import "moment-timezone";
import * as moment from "moment";

export class FuseUtils {
  /**
   * Filter array by string
   *
   * @param mainArr
   * @param searchText
   * @returns {any}
   */
  public static filterArrayByString(mainArr, searchText): any {
    if (searchText === "") {
      return mainArr;
    }

    searchText = searchText.toLowerCase();

    return mainArr.filter((itemObj) => {
      return this.searchInObj(itemObj, searchText);
    });
  }

  /**
   * Search in object
   *
   * @param itemObj
   * @param searchText
   * @returns {boolean}
   */
  public static searchInObj(itemObj, searchText): boolean {
    for (const prop in itemObj) {
      if (!itemObj.hasOwnProperty(prop)) {
        continue;
      }

      const value = itemObj[prop];

      if (typeof value === "string") {
        if (this.searchInString(value, searchText)) {
          return true;
        }
      } else if (Array.isArray(value)) {
        if (this.searchInArray(value, searchText)) {
          return true;
        }
      }

      if (typeof value === "object") {
        if (this.searchInObj(value, searchText)) {
          return true;
        }
      }
    }
  }

  /**
   * Search in array
   *
   * @param arr
   * @param searchText
   * @returns {boolean}
   */
  public static searchInArray(arr, searchText): boolean {
    for (const value of arr) {
      if (typeof value === "string") {
        if (this.searchInString(value, searchText)) {
          return true;
        }
      }

      if (typeof value === "object") {
        if (this.searchInObj(value, searchText)) {
          return true;
        }
      }
    }
  }

  /**
   * Search in string
   *
   * @param value
   * @param searchText
   * @returns {any}
   */
  public static searchInString(value, searchText): any {
    return value.toLowerCase().includes(searchText);
  }

  /**
   * Generate a unique GUID
   *
   * @returns {string}
   */
  public static generateGUID(): string {
    function S4(): string {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }

    return S4() + S4();
  }

  /**
   * Toggle in array
   *
   * @param item
   * @param array
   */
  public static toggleInArray(item, array): void {
    if (array.indexOf(item) === -1) {
      array.push(item);
    } else {
      array.splice(array.indexOf(item), 1);
    }
  }

  /**
   * Handleize
   *
   * @param text
   * @returns {string}
   */
  public static handleize(text): string {
    return text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, "-") // Replace spaces with -
      .replace(/[^\w\-]+/g, "") // Remove all non-word chars
      .replace(/\-\-+/g, "-") // Replace multiple - with single -
      .replace(/^-+/, "") // Trim - from start of text
      .replace(/-+$/, ""); // Trim - from end of text
  }

  public static fileParser(attachment: string) {
    if (attachment === "") {
      return {
        filePath: "--",
        fileName: "--",
        fileIcon: "--",
        fileType: "--",
      };
    }

    const temp = attachment.split(".com/");

    let fileWithGuid: string = temp[1];

    let file = fileWithGuid.substr(36, fileWithGuid.length);

    let fileBreakDown = file.split(".");

    let fileIcon = "";

    let type = fileBreakDown[1].toLocaleLowerCase();
    if (type === "pdf") {
      fileIcon = "assets/icons/document/PDF.png";
    } else if (type === "doc") {
      fileIcon =
        "https://firebasestorage.googleapis.com/v0/b/optimus-prime-6b029.appspot.com/o/icon%2FxlogDOCS.svg?alt=media&token=86d0c860-e4dd-476c-a60a-bd30dda82579";
    } else if (type === "xlsx") {
      fileIcon = "assets/icons/document/Excel.png";
    } else if (type === "png" || type === "jpg" || type === "jpeg") {
      fileIcon = attachment;
    } else {
      fileIcon =
        "https://firebasestorage.googleapis.com/v0/b/optimus-prime-6b029.appspot.com/o/icon%2Fdocument%20folder.svg?alt=media&token=6708d610-ee8e-4961-88f1-1fa4c4e40a26";
    }

    return {
      filePath: attachment,
      fileName: file,
      fileIcon: fileIcon,
      fileType: type,
    };
  }

  /**
   * Currency Formatter
   *
   * @param {*} data
   * @return {*}
   * @memberof FuseUtils
   */
  public static currencyFormat(data: number) {
    if (data !== null && typeof data !== "undefined") {
      let value = data.toString();
      let decimal = "00";
      let wholeNumber = "0";
      let thousands = "0";
      let found = value.includes(".");

      if (found) {
        // data with decimal
        decimal = value.substring(value.length - 2);
        if (decimal.includes(".")) {
          //if true, then decimal is single digit
          // if false, then decimal is double digit
          let singleDigit = decimal.replace(".", "");
          decimal = singleDigit + "0";
        }

        wholeNumber = value.slice(0, -2);
        if (wholeNumber.includes(".")) {
          //if true, then decimal is double digit
          // if false, then decimal is single digit
          wholeNumber = wholeNumber.replace(".", "");
        }
      } else {
        // data without decimal
        wholeNumber = value;
      }

      //Adds comma separator
      thousands = wholeNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      // Join to a single string
      let combined = [thousands, decimal];
      const final = combined.join(".");
      return final;
    } else {
      return "0.00";
    }
  }

  public static newCurrencyFormat(amount) {
    // Convert the number to a string
    let formatted = amount.toFixed(2);

    // Split the string into whole and decimal
    let [whole, decimal] = formatted.split(".");

    // Add commas to the whole part
    whole = whole.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // If decimal are undefined or less than 10, pad with a zero
    decimal = decimal === undefined ? "00" : decimal.padEnd(2, "0");

    // Combine whole and decimal with a dot separator
    return `${whole}.${decimal}`;
  }

  public static errorHandler(error) {
    if (error) {
      if (error?.error) {
        return error.error.message ? error.error.message : error.title;
      }
      if (error?.errors?.length > 0) {
        return error.errors[0]?.message
          ? error.errors[0]?.message
          : error.title;
      }
      if (error?.message) {
        return error.message;
      }
      if (error?.title) {
        return error.title;
      }
      return error;
    } else {
      return "Something went wrong!";
    }
  }

  /**
   * Converts date to local time (No Object)
   *
   * @static
   * @param {string} date
   * @param {string} [format='MMM DD, YYYY']
   * @return {*}  {string}
   * @memberof FuseUtils
   */
  public static plainConvertDateToLocal(
    date: string,
    format: string = "MMM DD, YYYY"
  ): string {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const d = new Date();
    const n = d.getTimezoneOffset();

    if (moment(date).isValid()) {
      return moment.utc(date).tz(timeZone).format(format);
    }
    return "--";
  }

  /**
   * get local timezoneoffset
   *
   * @static
   * @return {*}
   * @memberof FuseUtils
   */
  public static getLocalTimezone() {
    return new Date().getTimezoneOffset() / -60;
  }

  /**
   * Convert Date with no time zone
   *
   * @static
   * @param {string} date
   * @param {string} [format='MMM DD, YYYY hh:mm:ss a']
   * @return {*}  {string}
   * @memberof FuseUtils
   */
  public static convertDateNoTimeZone(
    date: string,
    format: string = "MMM DD, YYYY hh:mm:ss a"
  ): string {
    if (moment(date).isValid()) {
      return moment.utc(date).format(format);
    }
    return "--";
  }

  /**
   * Getting File Type of upload
   *
   * @static
   * @param {*} file
   * @return {*}
   * @memberof XlogUtils
   */
  public static fileType(file) {
    if (file.length === 0) {
      return;
    }
    const mimeType = file.type;
    let fileType = "";
    if (mimeType.match(/image\/*/)) {
      fileType = "image";
    } else if (mimeType.match(/pdf\/*/)) {
      fileType = "assets/icons/document/PDF.png";
    } else if (mimeType.match(/word\/*/)) {
      fileType = "assets/icons/document/Docx.png";
    } else if (
      mimeType.match(/excel\/*/) ||
      mimeType.match(/spreadsheetml\/*/) ||
      mimeType.match(/csv\/*/)
    ) {
      fileType = "assets/icons/document/Excel.png";
    } else {
      return null;
    }
    return fileType;
  }

  public static truckingStatus(): any[] {
    return [
      "New", // Shipper 0
      "Cancelled By Booking Party", // 1
      "Cancelled By Service Provider", // 2
      "Rejected", // Trucker 3
      "Confirmed Booking", // Trucker 4
      "Invoice Released", // Trucker 5
      "Invoice Revision", // Shipper 6
      "Process Payment", // Shipper 7
      "Payment Submitted", // Shipper 8
      "Payment Validation", // Trucker 9
      "Completed", // Trucker 10
      "Payment Successful", // Trucker 11
      "Payment Unsuccessful", // Trucker 12
      "Invoice For Payment Approval", // Trucker 13
      "For Payment", // Trucker 14
      "Approved Payment", // Shipper/Consignee 15
    ];
  }

  public static seaFreightStatus(): any[] {
    return [
      "Pending Confirmation", // 0
      "Cancelled By Booking Party", // 1
      "Cancelled By Service Provider", // 2
      "Rejected", // 3
      "Booking Confirmed", // 4
      "Booking Confirmed", // 5
      "Shipping Instructions - Pending Confirmation", // 6
      "Manage Shipping Instruction - Revised", // 7
      "Shipping Instruction - Confirmed", // 8
      "Shipping Instruction - Rejected", // 9
      "Bill of Lading Draft - Pending Confirmation", // 10
      "Bill of Lading Draft - Confirmed", // 11
      "Bill of Lading Draft - Request for Revision / Rejected", // 12
      "For Payment - Origin", // 13
      "Loading Confirmed", // 14
      "Final Bill of Lading Released", // 15
      "Arrival Notice Released", // 16
      "Destination Invoice Released", //17
      "For Payment - Destination", // 18
      "Delivery Order Released", // 19
      "Empty Container Released", // 20
      "Other Payment Charges", // 21
      "Completed", // 22
      "Return Empty Container", //23
      "Invoice Released - Origin", // 24
      "Payment Submitted - Origin", // 25
      "Payment Submitted - Destination", // 26
      "Payment Validation - Origin", // 27
      "Payment Validation - Destination", // 28
      "Partial Loading Confirmed", // Agent 37
      "Partial Released Empty Container", // Agent 38
      "Partial Returned Empty Container", // Agent 39
      "Origin Invoice Revision", // Shipper 40
      "Destination Invoice Revision", // Consignee 41
      "Origin Additional Charges Invoice Revision", // Shipper 42
      "Destination Additional Charges Invoice Revision", // Consignee 43
      "For Final Bill Of Lading Revision", // Consignee 44
      "Payment Approval - Origin", // 45
      "Payment Approval - Destination", // 46
      "Payment Unsuccessful - Origin", // 47
      "Payment Unsuccessful - Destination", // 48
      "Processing Payment - Origin", // 49
      "Processing Payment - Destination", // 50
      "Payment Approved - Origin", // 51
      "Payment Approved - Destination", // 52
      "Payment Successful - Origin", // 53
      "Payment Successful - Destination", // 54
      "Rebooked", // 54
      "Pending Partial Rebooking", //56
      "Pending Rebooking", // 57
      "Partially Rebooked", // 58
      "Pending Cancellation", // 59 Booking Party
      "Cancellation Rejected", // 60 -> not being used in any scenario yet
      "Pending Cancellation", // 61 Service Provider
    ];
  }
  public static seaFreightStatusEvent(eventId: number): string {
    if (eventId !== -1) {
      const statuses = [
        "Pending Confirmation", // 0
        "Cancelled By Booking Party", // 1
        "Cancelled By Service Provider", // 2
        "Rejected", // 3
        "Booking Confirmed", // 4
        "Booking Confirmed", // 5
        "Shipping Instructions - Pending Confirmation", // 6
        "Manage Shipping Instruction - Revised", // 7
        "Shipping Instruction - Confirmed", // 8
        "Shipping Instruction - Rejected", // 9
        "Bill of Lading Draft - Pending Confirmation", // 10
        "Bill of Lading Draft - Confirmed", // 11
        "Bill of Lading Draft - Request for Revision / Rejected", // 12
        "Accepted Invoice - Origin", // 13
        "Loading Confirmed",
        "Final Bill of Lading Released",
        "Arrival Notice Released",
        "Destination Invoice Released",
        "Accepted Invoice - Destination",
        "Delivery Order Released",
        "Empty Container Released",
        "Other Payment Charges",
        "Completed",
        "Return Empty Container",
        "Invoice Released - Origin",
        "Payment Submitted - Origin", // 25
        "Payment Submitted - Destination", // 26
        "Payment Validation - Origin", // 27
        "Payment Validation - Destination", // 28
        "Additional Charges Invoice Release - Origin", // Shipper 29
        "Additional Charges Payment Processing - Origin", // Shipper 30
        "Additional Charges Payment Submitted - Origin", // Shipper 31
        "Additional Charges Payment Validation - Origin", // Agent 32
        "Additional Charges Invoice Release - Destination", // Agent 33
        "Additional Charges Payment Processing - Destination", // Consignee 34
        "Additional Charges Payment Submitted - Destination", // Consignee 35
        "Additional Charges Payment Validation - Destination", // Agent 36
        "Partial Loading Confirmed", // Agent 37
        "Partial Released Empty Container", // Agent 38
        "Partial Returned Empty Container", // Agent 39
        "Origin Invoice Revision", // Shipper 40
        "Destination Invoice Revision", // Consignee 41
        "Origin Additional Charges Invoice Revision", // Shipper 42
        "Destination Additional Charges Invoice Revision", // Consignee 43
        "For Final Bill Of Lading Revision", // Consignee 44
        "Payment Approval - Origin", // 45
        "Payment Approval - Destination", // 46
        "Payment Unsuccessful - Origin", // 47
        "Payment Unsuccessful - Destination", // 48
        "Processing Payment - Origin", // 49
        "Processing Payment - Destination", // 50
        "Payment Approved - Origin", // 51
        "Payment Approved - Destination", // 52
        "Payment Successful - Origin", // 53
        "Payment Successful - Destination", // 54
        "Rebooked", // 54
        "Pending Partial Rebooking", //56
        "Pending Rebooking", // 57
        "Partially Rebooked", // 58
        "Pending Cancellation", // 59 Booking Party
        "Cancellation Rejected", // 60 -> not being used in any scenario yet
        "Pending Cancellation", // 61 Service Provider
      ];
      return statuses[eventId];
    }
    return "N / A";
  }

  public static getARAPEvent(eventId: number) {
    if (eventId !== -1) {
      const statuses = [
        "All",
        "New Invoice",
        "Pending Acceptance",
        "Invoice - Pending Revision",
        "Invoice - For Payment",
        "Invoice - Payment Unsuccessful",
        "Invoice - Paid",
        "Invoice - For Payment Approval",
        "Invoice - Processing Payment",
        "Invoice - Approved",
        "Invoice - For Payment Validation",
      ];
      return statuses[eventId];
    }
    return "N / A";
  }

  public static getTruckingStatus(eventId: number) {
    if (eventId !== -1) {
      const statuses = [
        "New", // Shipper 0
        "Cancelled By Booking Party", // Shipper 1
        "Cancelled By Service Provider", // Trucker 2
        "Rejected", // Trucker 3
        "Confirmed Booking", // Trucker 4
        "Invoice Released", // Trucker 5
        "Invoice Revision", // Shipper 6
        "Process Payment", // Shipper 7
        "Payment Submitted", // Shipper 8
        "Payment Validation", // Trucker 9
        "Completed", // Trucker 10
        "Payment Successful", // Trucker 11
        "Payment Unsuccessful", // Trucker 12
        "Invoice For Payment Approval", // Trucker 13
        "For Payment", // Trucker 14
        "Approved Payment", //
      ];
      return statuses[eventId];
    }
    return "N / A";
  }

  public static getFinanceEvent(eventId: number): string {
    if (eventId !== -1) {
      const statuses = [
        "All",
        "New Invoice",
        "Pending Acceptance",
        "Invoice - Pending Revision",
        "Invoice - for Payment",
        "Invoice - Payment Unsuccessful",
        "Invoice - Paid",
        "Invoice - For Payment Approval",
        "Invoice - Processing Payment",
        "Invoice - Approved",
        "Invoice - For Payment Validation",
      ];
      return statuses[eventId];
    }
    return "N / A";
  }

  public static FormatQuery(filter) {
    const newObj = {};
    Object.entries(filter).forEach(([k, v]) => {
      if (v === Object(v)) {
        newObj[k] = this.FormatQuery(v);
      } else {
        if (typeof v == "boolean" || (v != "" && v != null)) {
          newObj[k] = filter[k];
        }
      }
    });
    return newObj;
  }

  public static kycSectionStatus(statusCode: any) {
    switch (statusCode) {
      case "NEW":
        return "NEW";
      case "PEN":
        return "PENDING FOR APPROVAL";
      case "PER":
        return "PENDING FOR REVISION";
      case "APP":
        return "APPROVED";
      case "REJ":
        return "REJECTED";
      default:
        "NEW";
    }
  }
}
