<div class="p-8 w-100-p border border-radius-8">
  <p class="my-4">
    {{ incomingData?.label }} {{ incomingData?.isRequired ? "*" : "" }}
  </p>
  <div
    [maxFileSize]="5242880"
    [multiple]="false"
    class="custom-dropzone1 w-100-p"
    ngx-dropzone
    [multiple]="false"
    (change)="onSelect($event)"
    [accept]="incomingData.type ? incomingData.type : '*'"
  >
    <ngx-dropzone-label>
      <div>
        <mat-icon class="s-50">
          <img
            src="/assets/icons/document/ADD FILE ICON.png"
            class="fileImage"
            alt=""
          />
        </mat-icon>
      </div>
      <span class="font-weight-900 font-size-12">
        {{ incomingData?.isSingleUploader ? "Add File" : "Add File(s)" }}
      </span>
      <br />
      <span class="font-size-12">
        {{
          incomingData?.isSingleUploader
            ? "Drag and Drop File here to Upload!"
            : "Drag and Drop File(s) here to Upload!"
        }}</span
      >
    </ngx-dropzone-label>
    <div fxLayout="row" fxLayoutAlign="center center">
      <ngx-dropzone-preview
        ngProjectAs="ngx-dropzone-preview"
        *ngFor="let f of filesUploaded; let i = index"
      >
        <ngx-dropzone-label class="px-8" (click)="$event.stopPropagation()">
          <div class="item" *ngIf="f !== null">
            <div class="numberCircle cursor-pointer">
              <span class="close-badge" (click)="onRemove(i, $event)">x</span>
            </div>
            <img [src]="type === 'image' ? f.file : f.type" alt="" />
            <div class="drop-name font-size-12">{{ f.name }}</div>
          </div>
        </ngx-dropzone-label>
      </ngx-dropzone-preview>
    </div>
  </div>
</div>
