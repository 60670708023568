import { Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import {
  MatSnackBarRef,
  MAT_SNACK_BAR_DATA,
} from "@angular/material/snack-bar";
import { fuseAnimations } from "@fuse/animations";

@Component({
  selector: "app-notification",
  templateUrl: "./notification.component.html",
  styleUrls: ["./notification.component.scss"],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class NotificationComponent implements OnInit {
  hovered = false;
  data: any;
  constructor(
    public sbRef: MatSnackBarRef<NotificationComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public _data: any
  ) {
    this.data = _data;
  }

  ngOnInit(): void {
    // snackbar auto close every 3sec
    setTimeout(() => {
     this.close();
    }, 3000);
  }

  hoverData(event) {
    if (event.type === "mouseover") {
      this.hovered = true;
    } else {
      this.hovered = false;
      this.scheduleClose();
    }
    this.scheduleClose();
    return this.sbRef;
  }

  scheduleClose = () => {
    setTimeout(() => {
      if (!this.hovered) {
        this.sbRef.dismiss();
      }
    }, 3000);
  };

  close(){
    this.sbRef.dismiss();
  }
  
  action(){
    this.sbRef.dismissWithAction()
  }
}
