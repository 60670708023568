import { Injectable, OnDestroy, OnInit } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  NavigationEnd,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { select, Store } from "@ngrx/store";
import { RootState, selectUserInformation } from "app/core/store";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class GuardService implements CanActivate, OnDestroy , OnInit{
  private _unsubscribeAll: Subject<any>;
  constructor(private router: Router, private store: Store<RootState>) {
    this._unsubscribeAll = new Subject();
  }
  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const res = await new Promise((resolve, reject) => {
      this.store
        .pipe(select(selectUserInformation), takeUntil(this._unsubscribeAll))
        .subscribe(
          (result) => {
            if (result) {
              resolve(true);
            }
            resolve(false);
          },
          (err) => {
            resolve(false);
          }
        );
    });

    if (res) {
      if (route.data.access === 'coming-soon'){
        this.router.navigate([`${state.url}/coming-soon`],{});
      }
      if (route.data.access === 'no-permission-error'){
        this.router.navigate([`${state.url}/no-permission-error`],{});
      }
      return true;
    }
    this.router.navigate(["/auth/login"], { replaceUrl: true });
  }
}
