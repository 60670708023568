import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { UploaderService } from "app/services/uploader/uploader.service";

@Pipe({
  name: "protectedImage",
})
export class ProtectedImagePipe implements PipeTransform {
  constructor(private _sharedService: UploaderService) {}

  async transform(value: string) {
    if (value) {
      if (value.includes("storage")) {
        return new Promise((resolve, reject) => {
          this._sharedService.getClientImage(value).subscribe((res) => {
            if (res) {
              resolve(res.data && (res.data.presigneUrl as string));
            }
          });
        });
      } else if (value.includes("document")) {
        return new Promise((resolve, reject) => {
          this._sharedService.getTransactionImage(value).subscribe((res) => {
            if (res) {
              resolve(res.data && (res.data.presigneUrl as string));
            }
          });
        });
      } else {
        return value;
      }
    }
  }
}
