import { chatSupportActions, chatSupportActionTypes, IChatSupport } from "./chat-support.action";



export const initialState: IChatSupport = null;
export function reducer(state = initialState, action: chatSupportActions): IChatSupport {
    switch (action.type) {
        case chatSupportActionTypes.SetchatSupport:
            state = action.payload;
            return state;
        case chatSupportActionTypes.RemovechatSupport:
            state = initialState;
            return state;
        default:
            return state;
    }
}