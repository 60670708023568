import { Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { fuseAnimations } from "@fuse/animations";

@Component({
  selector: "app-file-viewer",
  templateUrl: "./file-viewer.component.html",
  styleUrls: ["./file-viewer.component.scss"],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class FileViewerComponent implements OnInit {
  file: any;
  isImage: boolean;

  constructor(
    public matDialogRef: MatDialogRef<FileViewerComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any
  ) {
    this.file = this._data.file;

    var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
    if (allowedExtensions.exec(this.file)) {
      this.isImage = true;
    }
  }

  ngOnInit(): void {
    // data
  }

  close() {
    this.matDialogRef.close();
  }
}
