import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { BehaviorSubject, Observable } from "rxjs";
import { AngularFireMessaging } from "@angular/fire/messaging";
import { FuseUtils } from "@fuse/utils";

@Injectable({
  providedIn: "root",
})
export class TransactionService {
  currentMessage = new BehaviorSubject(null);

  constructor(private http: HttpClient) {}

  getTripTicketList(filterOption, _api, _type?: string): Observable<any> {
    let params = new HttpParams();
    params = params.append("rowPerPage", filterOption.rowPerPage);
    params = params.append("pageNumber", filterOption.pageNumber);
    for (const key in filterOption) {
      if (key === "quickSearch") {
        if (filterOption[key]) {
          params = params.append("quickSearch", filterOption[key]);
        }
      }

      // Advance Search
      if (key === "queryOptions") {
        for (const prop in filterOption.queryOptions) {
          if (filterOption.queryOptions[prop]) {
            params = params.append(prop, filterOption.queryOptions[prop]);
          }
        }
      }

      if (key === "type") {
        params = params.append("type", filterOption.type);
      }

      if (key === "condition") {
        if (filterOption?.condition === "all") {
          params = params.append("exportAll", "true");
        } else {
          if (filterOption?.additionalColumn.length) {
            params = params.append(
              "additionalColumn",
              filterOption.additionalColumn
            );
          }
        }
      }

      if (key === "timezoneOffset") {
        params = params.append("timezoneOffset", filterOption.timezoneOffset);
      }
    }

    if (_type) {
      let headers = new HttpHeaders({
        Accept: _type === "csv" ? "csv" : "xlsx",
      });
      let responseType = `${_type === "csv" ? "text" : "blob"}` as "json";
      return this.http.get<any>(
        `${environment.truckingService}/oat/api/v1/${_api}`,
        {
          headers,
          responseType: responseType,
          params: params,
        }
      );
    } else {
      return this.list(params, _api);
    }
  }

  list(params, _api) {
    return this.http.get<any>(
      `${environment.truckingService}/oat/api/v1/${_api}`,
      {
        params: params,
      }
    );
  }

  csv(params, _api) {
    let headers = new HttpHeaders({
      Accept: "text/csv",
    });
    return this.http.get<any>(
      `${environment.truckingService}/oat/api/v1/${_api}`,
      {
        headers,
        responseType: "text" as "json",
        params: params,
      }
    );
  }

  getTripSummaryByReservationId(reservationId): Observable<any> {
    return this.http.get<any>(
      `${environment.truckingService}/oat/api/v1/GetServiceDetails-ReservationId?reservationId=${reservationId}`
    );
  }

  getTripDetails(data): Observable<any> {
    let params = new HttpParams();
    params = params.append("TripTicketNo", data.tripNo);
    params = params.append("JobOrderNo", data.jobOrderNo);
    return this.http.get<any>(
      `${environment.truckingService}/oat/api/v1/GetTripTicketDetails`,
      {
        params: params,
      }
    );
  }

  getTripDetailsHistory(data, type) {
    let params = new HttpParams();
    params = params.append("TripNo", data.tripNo);
    params = params.append("JobOrderNo", data.jobOrderNo);
    return this.http.get<any>(
      `${environment.truckingService}/oat/api/v1/${type}`,
      {
        params: params,
      }
    );
  }

  getBookingReservationList(filterOption, _type: string = ""): Observable<any> {
    let params = new HttpParams();
    params = params.append("pageSize", filterOption.rowPerPage);
    params = params.append("pageNumber", filterOption.pageNumber);
    if (filterOption.timezoneOffset) {
      params = params.append("timezoneOffset", filterOption.timezoneOffset);
    }
    if (filterOption.queryOptions) {
      params = params.append("query", filterOption.queryOptions);
    }
    if (filterOption.orderBy) {
      params = params.append("orderBy", filterOption.orderBy);
    }
    if (_type) {
      return this.http.get<any>(
        `${environment.transactionManagement}/otm/api/v1/cms/transactions/${_type}`,
        {
          params: params,
        }
      );
    } else {
      return this.http.get<any>(
        `${environment.transactionManagement}/otm/api/v1/cms/transactions`,
        {
          params: params,
        }
      );
    }
  }

  exportFileBookingReservation(payload, _apitype): Observable<any> {
    let headers = new HttpHeaders({
      Accept: payload.type === "csv" ? "text/csv" : "",
    });

    let responseType = `${payload.type === "csv" ? "text" : "blob"}` as "json";
    let params = new HttpParams();
    params = params.append("pageSize", payload.rowPerPage);
    params = params.append("pageNumber", payload.pageNumber);
    params = params.append("type", payload.type);
    if (payload.queryOptions) {
      params = params.append("query", payload.queryOptions);
    }
    if (payload.orderBy) {
      params = params.append("orderBy", payload.orderBy);
    }
    if (payload.timezoneOffset) {
      params.append("timezoneOffset", payload.timezoneOffset);
    }
    if (payload.condition === "all") {
      params = params.append("exportAll", "true");
    } else {
      if (payload.additionalColumn.length) {
        params = params.append("additionalColumn", payload.additionalColumn);
      }
    }

    return this.http.get<any>(
      `${environment.transactionManagement}/otm/api/v1/cms/transactions/${_apitype}`,
      { params: params, headers, responseType: responseType }
    );
  }

  getServiceDetails(_data): Observable<any> {
    return this.http.get<any>(
      `${environment.transactionManagement}/otm/api/v1/cms/transactions/${_data}`
    );
  }

  getTruckingdetails(_data, _api): Observable<any> {
    let params = new HttpParams({ fromObject: _data });
    return this.http.get<any>(
      `${environment.truckingService}/oat/api/v1/${_api}`,
      { params }
    );
  }

  getSeafreightTrackAndTrace(id): Observable<any> {
    return this.http.get<any>(
      `${environment.seaFreightService}/sfs/api/v1/transactions/sea-freight/${id}/track-trace`
    );
  }

  getSnapToRoad(tripInfo: any, companyId) {
    let params = new HttpParams().set("key", companyId);
    /* Additional param to be set on build */
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      "x-api-key": environment.trackTraceApiKey,
    });
    return this.http.get<any>(
      `${environment.trackAndTraceURL}/api/tracking/referenceNo/${tripInfo}/snapToRoad/lines`,
      { params: params, headers }
    );
  }

  playSnapToRoad(tripInfo: any, companyId) {
    let params = new HttpParams().set("key", companyId);
    /* Additional param to be set on build */
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      "x-api-key": environment.trackTraceApiKey,
    });
    return this.http.get<any>(
      `${environment.trackAndTraceURL}/api/tracking/referenceNo/${tripInfo}/snapToRoad/lines/combined/`,
      { params: params, headers }
    );
  }

  getPingAssetLocation(trackingDeviceId, startDate, endDate) {
    let params = new HttpParams()
      .set("imei", trackingDeviceId)
      .set("start", startDate)
      .set("end", endDate ?? new Date().toISOString());
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      "x-api-key": environment.trackTraceApiKey,
    });
    return this.http.get<any>(`${environment.trackAndTraceURL}/api/pingasset`, {
      params: params,
      headers,
    });
  }

  getOnlineDriverLocation(driverId: any) {
    /* Additional param to be set on build */
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      "x-api-key": environment.trackTraceApiKey,
    });
    return this.http.get<any>(
      `${environment.trackAndTraceURL}/api/tracking/driver/location/driver?ids=${driverId}`,
      { headers }
    );
  }

  getSOSListPerServiceTicket(serviceTicketId): Observable<any> {
    let params = new HttpParams().set("ServiceTicketId", serviceTicketId);
    return this.http.get<any>(
      `${environment.truckingService}/oat/api/driver-app/GetSOSListPerServiceTicket`,
      { params: params }
    );
  }

  getPingAssetCurrentLocation(trackingDeviceId = []) {
    let params = new HttpParams().set("ids", trackingDeviceId.join(","));
    let headers = new HttpHeaders({
      "Content-Type": "application/json",
      "x-api-key": environment.trackTraceApiKey,
    });
    return this.http.get<any>(
      `${environment.trackAndTraceURL}/api/pingasset/latest-locations`,
      { params: params, headers }
    );
  }
}
