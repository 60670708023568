import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule, Routes } from "@angular/router";
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { TranslateModule } from "@ngx-translate/core";
import "hammerjs";

import { FuseModule } from "@fuse/fuse.module";
import { FuseSharedModule } from "@fuse/shared.module";
import {
  FuseProgressBarModule,
  FuseSidebarModule,
  FuseThemeOptionsModule,
} from "@fuse/components";

import { fuseConfig } from "app/fuse-config";

import { AppComponent } from "app/app.component";
import { LayoutModule } from "app/layout/layout.module";
import { SampleModule } from "app/main/sample/sample.module";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { NotificationComponent } from "./core/component/notification/notification.component";
import { MatSelectModule } from "@angular/material/select";
import { MatFormFieldModule } from "@angular/material/form-field";
import { TaxModalComponent } from "./core/component/tax-modal/tax-modal.component";
import { FormsModule } from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { reducers, metaReducers } from "./core/store";
import { WarningComponent } from "./core/component/notification/warning/warning.component";
import { GlobalHttpInterceptorService } from "./core/interceptor/global-htttp-interceptor";
import { NgxMaskModule, IConfig } from "ngx-mask";
import { GuardService } from "./services/guard/guard.service";
import { NgDynamicBreadcrumbModule } from "ng-dynamic-breadcrumb";
import { CurrencyMaskInputMode, NgxCurrencyModule } from "ngx-currency";
import { TransactionService } from "./main/apps/transaction/transaction.service";
import { MessagingService } from "./services/messaging/messaging.service";
import { AngularFireMessagingModule } from "@angular/fire/messaging";
import { AngularFireModule } from "@angular/fire";
import { environment } from "environments/environment";

export const customCurrencyMaskConfig = {
  align: "left",
  allowNegative: false,
  allowZero: true,
  decimal: ".",
  precision: 2,
  prefix: "",
  suffix: "",
  thousands: ",",
  nullable: true,
  min: 0.0,
  max: null,
  inputMode: CurrencyMaskInputMode.FINANCIAL,
};

const appRoutes: Routes = [
  {
    path: "auth",
    loadChildren: () =>
      import("./main/auth/auth.module").then((m) => m.AuthModule),
  },
  {
    path: "main",
    loadChildren: () =>
      import("./main/apps/apps.module").then((m) => m.AppsModule),
    canActivate: [GuardService],
  },
  {
    path: "**",
    pathMatch: "full",
    redirectTo: "auth",
  },
];

@NgModule({
  declarations: [
    AppComponent,
    NotificationComponent,
    TaxModalComponent,
    WarningComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes),

    TranslateModule.forRoot(),
    StoreModule.forRoot(reducers, { metaReducers }),
    StoreDevtoolsModule.instrument(),

    NgxMaskModule.forRoot(),
    // Material moment date module
    MatMomentDateModule,

    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    // Material
    MatButtonModule,
    MatCheckboxModule,
    MatIconModule,
    MatSelectModule,
    MatFormFieldModule,
    FormsModule,
    MatInputModule,

    // Fuse modules
    FuseModule.forRoot(fuseConfig),
    FuseProgressBarModule,
    FuseSharedModule,
    FuseSidebarModule,
    FuseThemeOptionsModule,

    // App modules
    LayoutModule,
    SampleModule,

    // breadcrumbs
    NgDynamicBreadcrumbModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
  ],
  providers: [
    MessagingService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GlobalHttpInterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
