import { FuseNavigation } from "@fuse/types";

export const navigation: FuseNavigation[] = [
  {
    id: "applications",
    title: "",
    translate: "",
    type: "group",
    children: [
      {
        id: "dashboard",
        title: "Dashboard",
        translate: "",
        type: "item",
        icon: "xlog-dashboard",
        url: "/main/dashboard",
      },
      {
        id: "profile",
        title: "Profile",
        translate: "",
        type: "collapsable",
        icon: "xlog-profile",
        children: [
          // {
          //   id: "company-profile",
          //   title: "Company Profile",
          //   type: "item",
          //   url: "/profile",
          // },
          {
            id: "my-profile",
            title: "My Profile",
            type: "item",
            url: "/main/profile/my-profile",
          },
        ],
      },
      {
        id: "transaction",
        title: "Transactions",
        translate: "",
        type: "collapsable",
        icon: "xlog-transactions",
        children: [
          {
            id: "booking-reservation",
            title: "Booking Reservation",
            type: "item",
            url: "/main/transaction/booking-reservation",
          },
          {
            id: "service-details",
            title: "Service Details",
            type: "collapsable",
            children: [
              {
                id: "service-details-sea-freight",
                title: "Sea Freight",
                type: "item",
                url: "/main/transaction/service-details/sea-freight",
              },
              {
                id: "service-details-trucking",
                title: "Trucking",
                type: "item",
                url: "/main/transaction/service-details/trucking",
              },
            ],
          },
          {
            id: "trip-trickets",
            title: "Trip Tickets",
            type: "item",
            url: "/main/transaction/trip-tickets",
          },
        ],
      },
      {
        id: "plans-and-subscriptions",
        title: "Plans & Subscriptions",
        translate: "",
        type: "collapsable",
        icon: "xlog-plan-subscription",
        children: [
          {
            id: "country-plan",
            title: "Country Settings",
            type: "item",
            url: "/main/plans-and-subscriptions/country-settings",
          },
          {
            id: "company-plan",
            title: "Company Settings",
            type: "item",
            url: "/main/plans-and-subscriptions/company-settings",
          },
        ],
      },
      {
        id: "manage-accounts",
        title: "Manage Accounts",
        translate: "",
        type: "collapsable",
        icon: "xlog-manage-accounts",
        children: [
          {
            id: "manage-companies",
            title: "Companies",
            type: "item",
            url: "/main/manage-accounts/companies",
          },
          {
            id: "manage-users",
            title: "Users",
            type: "item",
            url: "/main/manage-accounts/users",
          },
          {
            id: "manage-drivers",
            title: "Drivers",
            type: "item",
            url: "/main/manage-accounts/drivers",
          },
          {
            id: "manage-operators",
            title: "Container Yard Operators",
            type: "item",
            url: "/main/manage-accounts/container-yard-operators",
          },
        ],
      },
      {
        id: "admin",
        title: "Administrator",
        translate: "",
        type: "collapsable",
        icon: "xlog-administrator",
        children: [
          {
            id: "admin-port",
            title: "Port Management",
            type: "item",
            url: "/main/administrator/port-management",
          },
          {
            id: "admin-commodities",
            title: "Commodities",
            type: "item",
            url: "/main/administrator/commodities",
          },
        ],
      },
      {
        id: "website",
        title: "Website Manager",
        translate: "",
        type: "item",
        icon: "xlog-website-manager",
        url: "/main/website",
      },
      {
        id: "audit-logs",
        title: "Audit Logs",
        translate: "",
        type: "collapsable",
        icon: "xlog-audit-logs",
        children: [
          {
            id: "audit-internal",
            title: "Internal",
            type: "item",
            url: "/main/audit-logs/internal",
          },
          {
            id: "audit-external",
            title: "External",
            type: "item",
            url: "/main/audit-logs/external",
          },
        ],
      },
      {
        id: "settings",
        title: "Settings",
        translate: "",
        type: "collapsable",
        icon: "xlog-settings",
        children: [
          {
            id: "settings-countries",
            title: "Countries",
            type: "item",
            url: "/main/settings/countries",
          },
          {
            id: "settins-currencies",
            title: "Currencies",
            type: "item",
            url: "/main/settings/currencies",
          },
          {
            id: "country-settins",
            title: "Country Settings",
            type: "item",
            url: "/main/settings/country-settings",
          },
        ],
      },
      {
        id: "access-management",
        title: "Access Management",
        translate: "",
        type: "collapsable",
        icon: "xlog-acm",
        children: [
          {
            id: "users",
            title: "Users",
            type: "item",
            url: "/main/access-management/acm-users",
          },
          {
            id: "profiles",
            title: "Profile",
            type: "item",
            url: "/main/access-management/acm-profile",
          },
        ],
      },
      // {
      //   id: "403",
      //   title: "403",
      //   translate: "",
      //   type: "item",
      //   icon: "",
      //   url: "/main/error/no-permission-error"
      // },
    ],
  },
];
