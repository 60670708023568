import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChangePasswordComponent } from 'app/main/auth/change-password/change-password.component';
import { ProfileChangePasswordComponent } from './profile-change-password.component';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { FlexLayoutModule } from '@angular/flex-layout';
import { GlobalAdvanceSearchModule } from 'app/main/apps/global-advance-search/global-advance-search.module';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTooltipModule } from '@angular/material/tooltip';
import { GlobalModule } from 'app/core/component/global/global.module';
import { SkeletonLoaderModule } from 'app/core/component/skeleton-loader/skeleton-loader.module';



@NgModule({
  declarations: [ ProfileChangePasswordComponent],
  imports: [
      MatButtonModule,
      MatFormFieldModule,
      MatIconModule,
      MatInputModule,
      MatDialogModule,
      FuseSharedModule,
      MatPasswordStrengthModule,
      CommonModule,
      FuseSharedModule,
      FlexLayoutModule,
      GlobalAdvanceSearchModule,
      MatInputModule,
      MatFormFieldModule,
      MatSelectModule,
      MatCheckboxModule,
      MatButtonModule,
      MatIconModule,
      MatMenuModule,
      MatSortModule,
      MatPaginatorModule,
      MatTooltipModule,
      GlobalModule,
      SkeletonLoaderModule,
  ],
  exports:[
    ProfileChangePasswordComponent
  ]
})
export class ProfileChangePasswordModule { }
