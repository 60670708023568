import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UploaderService {

  constructor(
    private http: HttpClient
  ) { }

  s3UploadImage(body): any {
    // return this.http.post(`${environment.globalCMS}/global/cms/api/v1/subMenuContent/upload`, body);
    return this.http.post(`${environment.globalCMSApi}/global/cms/api/v1/subMenuContent/upload`, body);
  }

  singleUploadImage(body): any {
    return this.http.post(`${environment.globalCMSApi}/global/cms/api/v1/file/upload`, body);
  }

  multipleUploadImage(body): any {
    return this.http.post(`${environment.globalCMSApi}/global/cms/api/v1/file/upload-multiple`, body);
  }


  getClientImage(payload): Observable<any> {
    return this.http.get(`${environment.globalCMSApi}/global/cms/api/v1/file/pre-signed?path=${payload}`)
  }
  
  getTransactionImage(payload): Observable<any> {
    return this.http.get(`${environment.transactionManagement}/otm/api/v1/transactions/file/generate-presigned-url?path=${payload}`
    );
  }
}
