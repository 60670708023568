import { ICommodities } from "app/core/models/commodities.model";
import { CommodityActions, CommodityActionTypes } from "./commodity.action";


export const initialState: ICommodities = null;
export function reducer(state = initialState, action: CommodityActions): ICommodities {
    switch (action.type) {
        case CommodityActionTypes.SetCommodity:
            state = action.payload;
            return state;
        case CommodityActionTypes.RemoveCommodity:
            state = initialState;
            return state;
        default:
            return state;
    }
}