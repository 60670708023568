import { Action } from "@ngrx/store";
import { ICommodities } from "app/core/models/commodities.model";

export enum CommodityActionTypes {
    SetCommodity = '[CommodityModel] Set CommodityModel',
    RemoveCommodity = '[CommodityModel] Remove CommodityModel',
}

export class SetCommodity implements Action {
    readonly type = CommodityActionTypes.SetCommodity;
    constructor(public payload: ICommodities) { }
}

export class RemoveCommodity implements Action {
    readonly type = CommodityActionTypes.RemoveCommodity;
    constructor() { }
}

export type CommodityActions = SetCommodity | RemoveCommodity;