import { AgmCoreModule } from "@agm/core";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatRippleModule } from "@angular/material/core";
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatToolbarModule } from "@angular/material/toolbar";
import { AgmDirectionModule } from "agm-direction";
import { GlobalAdvanceSearchModule } from "app/main/apps/global-advance-search/global-advance-search.module";
import { CurrencyMaskInputMode, NgxCurrencyModule } from "ngx-currency";
import { NgxDropzoneModule } from "ngx-dropzone";
import { ProtectedImageModule } from "../pipe/protected-image/protected-image.module";
import { ProtectedImagePipe } from "../pipe/protected-image/protected-image.pipe";
import { AuditLogsViewComponent } from "./audit-logs/audit-logs-view/audit-logs-view.component";
import { AuditLogsComponent } from "./audit-logs/audit-logs.component";
import { ChatSupportComponent } from "./chat-support/chat-support.component";
import { ECreditModalComponent } from "./e-credit-modal/e-credit-modal.component";
import { EmergencyTripComponent } from "./emergency-trip/emergency-trip.component";
import { FileUploaderComponent } from "./file-uploader/file-uploader.component";
import { FileViewerComponent } from "./file-viewer/file-viewer.component";
import { LegendsMapComponent } from "./legends-map/legends-map.component";
import { TripSimulatorComponent } from "./trip-simulator/trip-simulator.component";

export const customCurrencyMaskConfig = {
  align: "left",
  allowNegative: false,
  allowZero: true,
  decimal: ".",
  precision: 2,
  prefix: "",
  suffix: "",
  thousands: ",",
  nullable: true,
  min: 0.0,
  max: null,
  inputMode: CurrencyMaskInputMode.FINANCIAL,
};
@NgModule({
  declarations: [
    AuditLogsComponent,
    AuditLogsViewComponent,
    FileUploaderComponent,
    ChatSupportComponent,
    FileViewerComponent,
    TripSimulatorComponent,
    EmergencyTripComponent,
    LegendsMapComponent,
    ECreditModalComponent,
  ],
  imports: [
    CommonModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatButtonModule,
    MatGridListModule,
    MatIconModule,
    MatPaginatorModule,
    MatRippleModule,
    MatSelectModule,
    MatSortModule,
    MatTableModule,
    MatMenuModule,
    MatDialogModule,
    MatSnackBarModule,
    MatInputModule,
    GlobalAdvanceSearchModule,
    NgxDropzoneModule,
    ProtectedImageModule,
    MatRadioModule,
    MatToolbarModule,
    MatExpansionModule,
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyDnC23xX9YyLwYIbdx4nkegfRH6LTIrcP0",
      libraries: ["places"],
    }),
    AgmDirectionModule,
  ],
  exports: [
    AuditLogsComponent,
    FileUploaderComponent,
    ChatSupportComponent,
    ECreditModalComponent,
  ],
  providers: [ProtectedImagePipe],
})
export class GlobalModule {}
