import { Action } from "@ngrx/store";
import { Token } from "app/core/models/token.model";

export enum TokenActionTypes {
  SetToken = "[TokenModel] Set TokenModel",
  RemoveToken = "[TokenModel] Remove TokenModel",
}

export class SetToken implements Action {
  readonly type = TokenActionTypes.SetToken;
  constructor(public payload: Token) {}
}

export class RemoveToken implements Action {
  readonly type = TokenActionTypes.RemoveToken;
  constructor() {}
}

export type TokenActions = SetToken | RemoveToken;
