<div fxLayout="column" fxFlex fxLayoutGap="10px" class="w-100-p">
  <div class="pt-8" fxLayout="column">
    <h2 class="m-0" ngClass.xs="font-size-16 ">
      <strong class="text-uppercase">{{ data.title }}</strong>
    </h2>
  </div>

  <div
    fxLayout="row"
    class="px-48"
    fxLayoutAlign="space-between center"
    fxLayoutGap="10px"
  >
    <div>
      <div class="p-4">Action</div>
      <div class="p-4">Username</div>
      <div class="p-4">Status</div>
      <div class="p-4">Date/Time</div>
      <div class="p-4">Description</div>
    </div>
    <div class="text-bold">
      <div class="p-4">{{ data.value.action }}</div>
      <div class="p-4">{{ data.value.createdBy }}</div>
      <div class="p-4">{{ data.value?.status || "--" }}</div>
      <div class="p-4">
        {{ plainConvertDateToLocal(data.value?.createdOn) || "--" }}
      </div>
      <div class="p-4">{{ data.value?.description || "--" }}</div>
    </div>
  </div>

  <div
    fxLayout="column"
    class="px-48"
    fxLayoutAlign="start "
    fxLayoutGap="10px"
  >
    <div>
      <p ngClass.xs="font-size-16">Details</p>
    </div>
    <div class="detail-content">
      <ng-container *ngFor="let details of detailsValue">
        <div
          fxLayout="row"
          class="px-12"
          fxLayoutAlign="space-between center"
          fxLayoutGap="10px"
          *ngFor="let item of detailsKey"
        >
          <ng-container *ngIf="checkArray(item); else noArray">
            <div fxLayout="column" fxLayoutGap="10px" class="w-100-p">
              <div>
                <div class="p-4">{{ item }}</div>
              </div>
              <div *ngFor="let newItem of details[item]" class="w-100-p">
                <div
                  fxLayout="row"
                  class="px-12 py-4 w-100-p"
                  fxLayoutAlign="space-between center"
                  fxLayoutGap="10px"
                  *ngFor="let setItem of newItem | keyvalue"
                >
                  <div class="p-4">{{ setItem.key }}</div>
                  <div class="text-bold w-500 text-right">
                    {{ setItem.value }}
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-template #noArray>
            <div>
              <div class="p-4">{{ item }}</div>
            </div>
            <div class="text-bold w-500 text-right">
              {{ details[item] }}
            </div>
          </ng-template>
        </div>
      </ng-container>
    </div>
  </div>
</div>
