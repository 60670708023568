import { Action } from "@ngrx/store";

export interface IChatSupport {
    isChatOpen: any;
}

export enum chatSupportActionTypes {
    SetchatSupport = '[chatSupportModel] Set chatSupportModel',
    RemovechatSupport = '[chatSupportModel] Remove chatSupportModel',
}

export class SetchatSupport implements Action {
    readonly type = chatSupportActionTypes.SetchatSupport;
    constructor(public payload: IChatSupport) { }
}

export class RemovechatSupport implements Action {
    readonly type = chatSupportActionTypes.RemovechatSupport;
    constructor() { }
}

export type chatSupportActions = SetchatSupport | RemovechatSupport;