export const environment = {
  clientManagement: "https://client-management-api.demo.xlog.net",
  globalCMSApi: "https://global-cms-api.demo.xlog.net",
  truckingService: "https://trucking-service-api.demo.xlog.net",
  seaFreightService: "https://sea-freight-api.demo.xlog.net",
  transactionManagement: "https://transaction-service-api.demo.xlog.net",
  xlogChat: "https://smalltalkui.chatframe.calicubilly.com",
  googleMapsApiKey: "AIzaSyDnC23xX9YyLwYIbdx4nkegfRH6LTIrcP0",
  globalAggregator: "https://global-aggregator-api.demo.xlog.net",
  production: true,
  hmr: false,
  psgRequired: false,
  firebase: {
    apiKey: "AIzaSyDpKrQ73GqsM7HAgGTqBUbmBU0q8nR8Vss",
    authDomain: "optimus-realtime.firebaseapp.com",
    databaseURL: "https://optimus-realtime.firebaseio.com",
    projectId: "optimus-realtime",
    storageBucket: "optimus-realtime.appspot.com",
    messagingSenderId: "713358032967",
    appId: "1:713358032967:web:38c2104466d42e1ccaf162",
  },
  trackTraceApiKey: "vfQRM5GqMc1cfycOoqcIY8Lyu2fDTejN2JO4KXsS",
  trackAndTraceURL: "https://track-and-trace-api.demo.xlog.net",
  developerMap: true,
  HcaptchaKey: "cd879908-b86a-4aee-a49e-c122c8fbe522",
};
