import { CompanySettingsModel } from "app/core/models/company-settings.model";
import { CompanySettingsActions, CompanySettingsActionTypes } from "./company-settings.action";

export const initialState: CompanySettingsModel = null;
export function reducer(state = initialState, action: CompanySettingsActions): CompanySettingsModel {
    switch (action.type) {
        case CompanySettingsActionTypes.SetCompanySettings:
          state = action.payload;
          return state;
        case CompanySettingsActionTypes.RemoveCompanySettings:
          state = initialState;
          return state;
        default:
          return state;
      }
}