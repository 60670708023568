<mat-toolbar class="p-0 mat-elevation-z1" fxLayout="column">
  <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">
    <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">
      <button mat-icon-button class="navbar-toggle-button" (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
        <mat-icon class="secondary-text">menu</mat-icon>
      </button>
      <button mat-icon-button class="navbar-toggle-button" (click)="toggleSidebarFolded()" fxHide fxShow.gt-md>
        <mat-icon class="secondary-text">menu</mat-icon>
      </button>
      <!--  (click)="toggleSidebarOpen('navbar')"
                fxHide.gt-md -->
      <div class="toolbar-separator" *ngIf="!hiddenNavbar && !rightNavbar" fxHide.gt-md></div>

      <!-- <div fxLayout="row" fxLayoutAlign="start center" *ngIf="horizontalNavbar">
                      <div class="logo ml-16">
                          <img class="logo-icon" src="assets/images/logos/fuse.svg">
                      </div>
                  </div>
      
                  <div class="px-8 px-md-16">
                      <fuse-shortcuts [navigation]="navigation"></fuse-shortcuts>
                  </div> -->
    </div>

    <div class="" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">
      <button mat-button class="user-button">
        <!-- [matMenuTriggerFor]="userMenu" -->
        <div fxLayout="row" fxLayoutAlign="center center">
          <img class="avatar object-fit mr-0 mr-sm-16" src="../../../../assets/images/backgrounds/XLOG-CMS@2x.png" />
          <div fxLayout="column" fxLayoutAlign="center center">
            <div>
              <span class="username mr-12 font-weight-700" fxHide fxShow.gt-sm>Shiptek Solutions Corp.
              </span>
            </div>
            <div>
              <span class="mr-12" fxHide fxShow.gt-sm>Global</span>
            </div>
          </div>
          <mat-icon fxHide.xs [matMenuTriggerFor]="settings">settings</mat-icon>
        </div>
      </button>

      <mat-menu #userMenu="matMenu" [overlapTrigger]="false">
        <!-- <button mat-menu-item>
                          <mat-icon>account_circle</mat-icon>
                          <span>My Profile</span>
                      </button>
      
                      <button mat-menu-item class="">
                          <mat-icon>mail</mat-icon>
                          <span>Inbox</span>
                      </button>

        <button mat-menu-item class="">
          <mat-icon>exit_to_app</mat-icon>
          <span>Logout</span>
        </button>-->
      </mat-menu>

      <mat-menu #settings="matMenu" [overlapTrigger]="false" class="mt-16">
        <div class="pt-12 pb-4 px-16 border-bottom">
          <p class="m-0 mb-8">Signed in as</p>
          <p class="m-0 mb-4 font-weight-700">
            {{ user?.firstName }} {{ user?.lastName }}
          </p>
          <p class="m-0 mb-8">{{ user?.emailAddress }}</p>
        </div>
        <button mat-menu-item (click)="logout()" fxFlex fxLayoutAlign="start center" fxLayoutGap="8px" class="px-16">
          <svg class="inline-block mr-2" fill="none" stroke="currentColor" viewBox="0 0 41 36"
            xmlns="http://www.w3.org/2000/svg" width="22" height="22">
            <path stroke-width="1" stroke="#333" d="M13.5,0V4.5h18V27h-18v4.5H36V0ZM9,9,0,15.75,9,22.5V18H27V13.5H9Z"
              transform="translate(2 2)" fill="#333"></path>
          </svg>
          <span>Logout</span>
        </button>
      </mat-menu>

      <!-- <div class="toolbar-separator"></div> -->

      <!-- <fuse-search-bar (input)="search($event)"></fuse-search-bar>
      
                  <div class="toolbar-separator"></div>
      
                  <button mat-button fxHide fxShow.gt-xs
                          class="language-button"
                          [matMenuTriggerFor]="languageMenu">
                      <div fxLayout="row" fxLayoutAlign="center center">
                          <img class="flag mr-8" [src]="'assets/icons/flags/'+selectedLanguage.flag+'.png'">
                          <span class="iso text-uppercase">{{selectedLanguage.id}}</span>
                      </div>
                  </button>
      
                  <mat-menu #languageMenu="matMenu" [overlapTrigger]="false">
      
                      <button mat-menu-item *ngFor="let lang of languages" (click)="setLanguage(lang)">
                          <span fxLayout="row" fxLayoutAlign="start center">
                              <img class="flag mr-16" [src]="'assets/icons/flags/'+lang.flag+'.png'">
                              <span class="iso">{{lang.title}}</span>
                          </span>
                      </button>
      
                  </mat-menu>
      
                  <div class="toolbar-separator" fxHide fxShow.gt-xs></div>
      
                  <button mat-icon-button
                          class="quick-panel-toggle-button"
                          (click)="toggleSidebarOpen('quickPanel')"
                          aria-label="Toggle quick panel">
                      <mat-icon class="secondary-text">format_list_bulleted</mat-icon>
                  </button>
      
                  <div class="toolbar-separator" *ngIf="!hiddenNavbar && rightNavbar" fxHide fxShow.gt-xs></div>
      
                  <button mat-icon-button class="navbar-toggle-button"
                          *ngIf="!hiddenNavbar && rightNavbar" (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
                      <mat-icon class="secondary-text">menu</mat-icon>
                  </button> -->
    </div>
  </div>
  <!-- <div
  *ngIf="showBreadcrumbs"
    class="accent-500 w-100-p py-4 px-12"
    fxLayout="row"
    fxLayoutAlign="start center"
  >
    <ng-container *ngFor="let item of breadcrumbList">
      <ng-container
        *ngFor="
          let bread of item.label?.labels;
          let i = last;
          let x = index;
          let y = first
        "
      >
        <span
          class="font-size-16 text-capitalize cursor-pointer"
          [ngClass]="{ 'ml-12': y == true }"
          (click)="breadClick(bread, x)"
          >{{ item.label.routes[x] }}</span
        >
        <mat-icon class="s-16" *ngIf="i == false"
          >keyboard_arrow_right</mat-icon
        >
      </ng-container>
    </ng-container>
  </div> -->

</mat-toolbar>