import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { fuseAnimations } from '@fuse/animations';
import { Store } from '@ngrx/store';
import { RootState } from 'app/core/store';
import { SharedService } from 'app/services/shared.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'chat-support',
  templateUrl: './chat-support.component.html',
  styleUrls: ['./chat-support.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class ChatSupportComponent implements OnInit {
  showChat: boolean = false;
  iframeUrl: any;
  userName: string = 'CMS-Chat Support';
  constructor(
    private _sharedService: SharedService,
    private store: Store<RootState>,
    private sanitizer: DomSanitizer,
  ) { }

  ngOnInit(): void {
    this._sharedService.chatSupport.subscribe((data) => {
      this.showChat = data;

      this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`${environment.xlogChat}/?username=${this.userName}`)
    })
  }

  access() {
    document.getElementById("main_iframe");
  }

  closeIframe() {
    this._sharedService.setChatSupport(false)
  }

  iconClick() {
    let data = {
      isShowChatBody: false,
      isShowChatHead: true
    }
    this._sharedService.setChatSupport(true)

  }
}
