import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { filter, head } from "lodash";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class CountrySettingsService {
  headers = new HttpHeaders();

  constructor(private http: HttpClient) {}

  getCountrySettingsListing(filterOption): Observable<any> {
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");

    let params = new HttpParams();
    params = params.append("rowPerPage", filterOption.rowPerPage);
    params = params.append("pageNumber", filterOption.pageNumber);

    // Quick Search
    for (const key in filterOption) {
      if (key === "quickSearch") {
        if (filterOption[key]) {
          params = params.append("quickSearch", filterOption[key]);
        }
      }

      // Advance Search
      if (key === "queryOptions") {
        for (const prop in filterOption.queryOptions) {
          if (filterOption.queryOptions[prop]) {
            params = params.append(prop, filterOption.queryOptions[prop]);
          }
        }
      }
    }

    if (filterOption.isDescending !== null && filterOption.orderBy) {
      params = params.append("orderBy", filterOption.orderBy);
      params = params.append("isDescending", filterOption.isDescending);
    }

    return this.http.get<any>(
      `${environment.globalCMSApi}/global/cms/api/v1/country-settings-list`,
      {
        params: params,
        headers: headers,
      }
    );
  }

  getPricingModelListingCountrySetting(filterOption): Observable<any> {
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");

    let params = new HttpParams();
    params = params.append("rowPerPage", filterOption.rowPerPage);
    params = params.append("pageNumber", filterOption.pageNumber);

    // Quick Search
    for (const key in filterOption) {
      if (key === "quickSearch") {
        if (filterOption[key]) {
          params = params.append("quickSearch", filterOption[key]);
        }
      }

      // Advance Search
      if (key === "queryOptions") {
        for (const prop in filterOption.queryOptions) {
          if (filterOption.queryOptions[prop]) {
            params = params.append(prop, filterOption.queryOptions[prop]);
          }
        }
      }
    }

    if (filterOption.isDescending !== null && filterOption.orderBy) {
      params = params.append("orderBy", filterOption.orderBy);
      params = params.append("isDescending", filterOption.isDescending);
    }

    return this.http.get<any>(
      `${environment.globalCMSApi}/global/cms/api/v1/country-settings-pricing-model-list`,
      {
        params: params,
        headers: headers,
      }
    );
  }

  deletePricingModal(id): Observable<any> {
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    headers.append("Access-Control-Allow-Origin", "*");

    let params = new HttpParams();
    params = params.append("pricingModelSettingsId", id);

    return this.http.delete(
      `${environment.globalCMSApi}/global/cms/api/v1/pricingmodel`,
      {
        params: params,
        headers: headers,
      }
    );
  }

  pricingModelStatus(payload): Observable<any> {
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    headers.append("Access-Control-Allow-Origin", "*");

    return this.http.put(
      `${environment.globalCMSApi}/global/cms/api/v1/pricingmodel-activate-deactivate?pricingModelSettingsId=${payload.id}&status=${payload.status}`,
      {
        headers: headers,
      }
    );
  }

  getCountryandTax(): Observable<any> {
    return this.http.get<any>(
      `${environment.globalCMSApi}/global/cms/api/v1/countrytaxtype-list`
    );
  }

  getStatebyCountry(countryid): Observable<any> {
    return this.http.get<any>(
      `${environment.globalCMSApi}/global/cms/api/v1/country-settings-states-list?countryId=${countryid}`
    );
  }

  getCountrySettingsById(id): Observable<any> {
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");

    let params = new HttpParams();
    params = params.append("CountrySettingsId", id);
    return this.http.get<any>(
      `${environment.globalCMSApi}/global/cms/api/v1/country-settings-list`,
      {
        params: params,
        headers: headers,
      }
    );
  }

  updateStatus(payload): Observable<any> {
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    headers.append("Access-Control-Allow-Origin", "*");
    return this.http.put<any>(
      `${environment.globalCMSApi}/global/cms/api/v1/country-settings-activate-deactivate?countrySettingsId=${payload.countrySettingsId}&status=${payload.status}`,
      { headers: headers }
    );
  }

  updateCountrySettingsDetails(payload): Observable<any> {
    return this.http.put<any>(
      `${environment.globalCMSApi}/global/cms/api/v1/country-settings`,
      payload
    );
  }

  deleteCountrySettings(id, state = null): Observable<any> {
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    headers.append("Access-Control-Allow-Origin", "*");

    let params = new HttpParams();
    params = params.append("countryId", id);
    if (state !== null) {
      params = params.append("stateId", state?.stateId);
    }
    return this.http.delete(
      `${environment.globalCMSApi}/global/cms/api/v1/country-settings`,
      {
        params: params,
        headers: headers,
      }
    );
  }

  insertCountrySettingsTax(payload) {
    return this.http.post<any>(
      `${environment.globalCMSApi}/global/cms/api/v1/country-tax-settings-add`,
      payload
    );
  }

  updateCountrySettingsTax(payload) {
    return this.http.put<any>(
      `${environment.globalCMSApi}/global/cms/api/v1/country-tax-settings-update`,
      payload
    );
  }

  deleteCountrySettingsTax(id) {
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    headers.append("Access-Control-Allow-Origin", "*");

    return this.http.delete<any>(
      `${environment.globalCMSApi}/global/cms/api/v1/country-tax-settings-delete/${id}`,
      {
        headers: headers,
      }
    );
  }

  getPricingModelDetails(id) {
    let params = new HttpParams();
    params = params.append("pricingModelSettingsId", id);
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.get<any>(
      `${environment.globalCMSApi}/global/cms/api/v1/pricingmodel`,
      { params: params, headers: headers }
    );
  }

  insertCountrySettings(payload) {
    return this.http.post<any>(
      `${environment.globalCMSApi}/global/cms/api/v1/country-settings`,
      payload
    );
  }

  createPricingModel(payload) {
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.post<any>(
      `${environment.globalCMSApi}/global/cms/api/v1/pricingmodel`,
      payload,
      { headers: headers }
    );
  }

  // VALIDATE PRICING DUPLICATE
  validatePricingDuplicate(
    pricingModelServicesCode,
    countrySettingsId
  ): Observable<any> {
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    let params = new HttpParams();
    params = params.append(
      "pricingModelServicesCode",
      pricingModelServicesCode
    );
    params = params.append("countrySettingsId", countrySettingsId);
    return this.http.get<any>(
      `${environment.globalCMSApi}​/global/cms/api/v1/pricingmodel-validate-duplicate`,
      { params: params, headers: headers }
    );
  }

  getPricingModelLookup() {
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");
    return this.http.get<any>(
      `${environment.globalCMSApi}/global/cms/api/v1/pricingmodel-lookup`,
      { headers: headers }
    );
  }
}
