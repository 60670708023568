import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  ActionReducer,
  MetaReducer,
} from "@ngrx/store";
import { storageSync } from "@larscom/ngrx-store-storagesync";

import * as fromCompanySettings from "./../store/company-settings/company-settings.reducer";
import * as fromToken from "./../store/token/token.reducer";
import * as fromUserInformation from "./../store/user-info/user-info.reducer";
import * as commodityReducer from "./../store/commodity/commodity.reducer";
import * as portReducer from "./../store/ports/port.reducer";
import * as chatSupportReducer from "./../store/chat-support/chat-support.reducer";

import { CompanySettingsModel } from "../models/company-settings.model";
import { Token } from "../models/token.model";
import { UserInformation } from "../models/user-info.model";
import { ICommodities } from "../models/commodities.model";
import { IPorts } from "../models/ports.model";
import { IChatSupport } from "./chat-support/chat-support.action";

export interface RootState {
  companySettings: CompanySettingsModel;
  token: Token;
  userInformation: UserInformation;
  commodity: ICommodities;
  port: IPorts;
  chatSupport: IChatSupport;
}

export const reducers: ActionReducerMap<RootState> = {
  companySettings: fromCompanySettings.reducer,
  token: fromToken.reducer,
  userInformation: fromUserInformation.reducer,
  commodity: commodityReducer.reducer,
  port: portReducer.reducer,
  chatSupport: chatSupportReducer.reducer,
};

export function storageSyncReducer(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return storageSync<RootState>({
    features: [
      { stateKey: "companySettings" },
      { stateKey: "token" },
      { stateKey: "userInformation" },
      { stateKey: "commodity" },
      { stateKey: "port" },
      { stateKey: "chatSupport" },
    ],
    storage: window.localStorage,
  })(reducer);
}
export const metaReducers: Array<MetaReducer<any, any>> = [storageSyncReducer];

// Company Settings
export const selectCompanySettingsState =
  createFeatureSelector<CompanySettingsModel>("companySettings");

export const selectCompanySettings = createSelector(
  selectCompanySettingsState,
  (state) => state
);

export const selectTokenState = createFeatureSelector<Token>("token");

export const selectToken = createSelector(selectTokenState, (state) => state);

export const selectUserInformationState =
  createFeatureSelector<UserInformation>("userInformation");

export const selectUserInformation = createSelector(
  selectUserInformationState,
  (state) => state
);
export const selectCommodityState =
  createFeatureSelector<ICommodities>("commodity");

export const selectCommodity = createSelector(
  selectCommodityState,
  (state) => state
);

export const selectPortState = createFeatureSelector<IPorts>("port");

export const selectPort = createSelector(selectPortState, (state) => state);

export const selectChatState =
  createFeatureSelector<IChatSupport>("chatSupport");

export const selectChat = createSelector(selectChatState, (state) => state);
