import { MapsAPILoader } from "@agm/core";
import {
  Component,
  Inject,
  NgZone,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { fuseAnimations } from "@fuse/animations";
import { FuseUtils } from "@fuse/utils";
import { TransactionService } from "app/main/apps/transaction/transaction.service";
import { from, of, Subscription } from "rxjs";
import { concatMap, delay, finalize } from "rxjs/operators";
import { NotificationComponent } from "../../notification/notification.component";
import { WarningComponent } from "../../notification/warning/warning.component";
import { PopUpComponent } from "../../pop-up/pop-up.component";
import { EmergencyTripComponent } from "../emergency-trip/emergency-trip.component";

@Component({
  selector: "app-trip-simulator",
  templateUrl: "./trip-simulator.component.html",
  styleUrls: ["./trip-simulator.component.scss"],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class TripSimulatorComponent implements OnInit {
  data: any;
  markers = [];
  tripDetails: any;
  ac: any;
  report: any;
  sos: boolean = false;
  isLegendHidden: Boolean = true;
  zoom = 12;
  // Agm map property
  lat?: any = 0;
  lng?: any = 0;

  //planned Route
  originPlanned: { lat: number; lng: number };
  destinationPlanned: { lat: number; lng: number };

  iconActualStart = "assets/images/map-pins/ActualStart.png";

  iconActualOrigin = "assets/images/map-pins/ActualOrigin.png";

  iconActualDestination = "assets/images/map-pins/Destination.png";

  containerPoint = "assets/images/map-pins/historicalPlot_Icon@2x.png";

  containerIcon = "assets/images/map-pins/container_Icon@2x.png";

  truckIcon = "assets/images/map-pins/truck-pin.png";

  styles: any[] = [
    {
      featureType: "administrative",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#044F71",
        },
      ],
    },
    {
      featureType: "landscape",
      elementType: "all",
      stylers: [
        {
          color: "#f2f2f2",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "all",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "all",
      stylers: [
        {
          saturation: -100,
        },
        {
          lightness: 45,
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "all",
      stylers: [
        {
          visibility: "simplified",
        },
      ],
    },
    {
      featureType: "road.arterial",
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "transit",
      elementType: "all",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "all",
      stylers: [
        {
          color: "#C1E3F4",
        },
        {
          visibility: "on",
        },
      ],
    },
  ];

  renderOptions = {
    suppressMarkers: true,
    polylineOptions: {
      strokeOpacity: 0,
      icons: [
        {
          icon: {
            path: "M 0,-1 0,1",
            strokeOpacity: 0.8,
            scale: 2,
          },
          offset: "0",
          repeat: "12px",
        },
      ],
    },
  };

  coordinatesCombined = [];
  $destroyed: Subscription;
  time = 0;
  isPlayedShow = false;
  isPlayed: boolean = false;
  simulated: boolean = false;
  playTrip = [];
  markerPlay = [];
  filterMarker = [];
  coordinates = [];
  containerNumber = new FormControl("");
  containerInventory: any = [];
  tripDetailsData: any = [];
  selectedContainer: any = [];
  selectedContainerRoute: string;
  selectedContainerMobile: any = [];
  selectedRoute: any = [];
  serviceTicketId: any = [];
  selectedOption: any = [];
  isExpanded: boolean = false;
  selectedTripDetails: any = [];
  simulateArray: any = [];
  type: any;
  containerNumberArray: any[] = [];
  tripLevel: boolean;
  trackingDeviceId: any;
  tripStatus: string;
  containerNumberChecker: boolean = false;

  constructor(
    public matDialogRef: MatDialogRef<TripSimulatorComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private zone: NgZone,
    public dialog: MatDialog,
    private mapsAPILoader: MapsAPILoader,
    private activatedRoute: ActivatedRoute,
    private trackTraceService: TransactionService
  ) {
    this.data = _data;

    this.getInitialData();
  }

  ngOnInit(): void {
    this.sosChecker();
    this.selectedRoute = `ROUTE ${this.validateTripDetailsSequence(
      this.data?.tripDetails
    )}: ${this.getInitialSeletedRoute()}`;
    this.selectedContainer = this.getInitialSeletedContainer("web");
    if (this.type == "driver") {
      this.containerNumberArray =
        this.tripDetailsData[0].containersInfo[0]?.containerNumber;
      this.containerNumberChecker = Array.isArray(this.containerNumberArray);
    }
    this.selectedContainerMobile = this.getInitialSeletedContainer("mobile");
  }

  validateTripDetailsSequence(tripDetails) {
    if (Array.isArray(tripDetails)) {
      return this.getGroup(tripDetails[0].sequenceNo);
    } else {
      return this.getGroup(tripDetails.sequenceNo);
    }
  }

  getInitialData() {
    const {
      report,
      coordinatesCombined,
      tripDetails,
      markers,
      origin,
      destination,
      disableClose,
      selectedOption,
      containerInformationArray,
      data,
      tripLevel,
    } = this.data;
    this.coordinatesCombined = coordinatesCombined;
    this.tripDetails = tripDetails;
    this.ac = tripDetails?.ac;
    this.selectedOption = selectedOption;
    this.simulateArray = containerInformationArray;
    this.type = data;
    this.tripLevel = tripLevel;
    this.getContainerArray();
    if (Array.isArray(tripDetails)) {
      this.tripStatus = tripDetails[0]?.status;
    } else {
      this.tripStatus = tripDetails?.tripStatus;
    }

    this.activatedRoute.params.subscribe((params) => {
      this.serviceTicketId = params.id;
    });

    this.markers = markers?.map((el) => {
      if (el?.animation !== "BOUNCE") {
        el["markerToShow"] = true;
      } else {
        el["markerToShow"] = false;
      }
      el.latitude = parseFloat(el.latitude);
      el.longitude = parseFloat(el.longitude);
      return el;
    });
    this.filterMarker = this.markers.filter(
      (element) => element.markerToShow === false
    );
    let found = this.markers.findIndex(
      (element) => element.reportIcon === true
    );
    if (found !== -1) {
      this.sos = true;
    }
    // this.filterMarker = this.markers.filter(element => element.markerToShow!== false)
    this.originPlanned = origin;
    this.destinationPlanned = destination;
    this.report = report;
    this.matDialogRef.disableClose = disableClose;
    // this.setPlannedRouteAsCenter()
  }

  getCombinedCoordinates(value) {
    const index = value.sequenceNo - 1;
    this.coordinatesCombined = this.simulateArray[index];
  }

  getInitialSeletedRoute() {
    let jobOrderNo;
    let tripNo;
    jobOrderNo = Array.isArray(this.data?.tripDetails)
      ? this.data?.tripDetails[0].jobOrderNo
      : this.data?.tripDetails.jobOrderNo;
    tripNo = Array.isArray(this.data?.tripDetails)
      ? this.data?.tripDetails[0].tripNo
      : this.getTrip();

    return `${jobOrderNo} / ${tripNo}`;
  }

  getTrip() {
    if (this.data?.tripDetails?.tripNo) {
      return this.data?.tripDetails?.tripNo;
    } else {
      return this.data?.tripDetails?.ac?.tripNo;
    }
  }

  getInitialSeletedContainer(type) {
    let containerNumber;
    let trackingDeviceId;
    this.selectedContainerRoute = this.getGroup(
      this.containerInventory[0].sequenceNo
    );
    containerNumber = this.containerInventory[0]?.containerNumber;
    trackingDeviceId = this.containerInventory[0]?.trackingDeviceId;
    this.trackingDeviceId = this.containerInventory[0]?.trackingDeviceId;
    if (type == "web") {
      return `Container Number: ${containerNumber || "--"} (IMEI: ${
        trackingDeviceId || "--"
      })`;
    } else if (type == "mobile") {
      return `Container Number: ${containerNumber || "--"}`;
    }
  }

  /**
   * Infowindow
   *
   * @param {*} i
   * @param {*} infoWindow
   * @memberof TripSimulationComponent
   */
  mouseOver(i, infoWindow) {
    this.markers[i].open = true;
    infoWindow.open();
  }

  /**
   * Infowindow
   *
   * @param {*} i
   * @param {*} infoWindow
   * @memberof TripSimulationComponent
   */
  mouseOut(i, infoWindow) {
    if (this.markers[i].mouseClickedOpen == true) {
      infoWindow.open();
    } else {
      infoWindow.close();
    }
  }

  /**
   * Date Converter
   *
   * @param {string} date
   * @param {string} [type="MMM DD, YYYY hh:mm:ss a"]
   * @return {*}
   * @memberof TripSimulationComponent
   */
  plainConvertDateToLocal(date: string, type = "MMM DD, YYYY hh:mm:ss a") {
    return FuseUtils.plainConvertDateToLocal(date, type);
  }

  /**
   * SOS Details
   *
   * @param {*} report
   * @param {*} i
   * @memberof TripSimulationComponent
   */
  mousedblClicked(report, i): void {
    if (report?.reportIcon) {
      const dialogRef = this.dialog.open(EmergencyTripComponent, {
        width: "30%",
        panelClass: "trip-details-modal",
        autoFocus: null,
        data: {
          report: this.report,
          title: "Trip Details",
          disableClose: true,
        },
      });
    }
  }

  /**
   * Check if there was an SOS during trip
   *
   * @return {*}
   * @memberof TripSimulationComponent
   */
  sosChecker() {
    let found = this.markers.findIndex(
      (element) => element.reportIcon === true
    );
    if (found !== -1) {
      this.sos = true;
    }
  }

  /**
   * Play/Pause button
   *
   * @memberof TripDetailsComponent
   */
  showPlaySnapToRoad(type) {
    if (this.type === "container") {
      if (!this.trackingDeviceId) {
        this.showWarningAlert(
          "ERROR !",
          "error",
          `<p>Simulation not available. Scan/enter the IMEI using the driver app to begin tracking."</p>`,
          "Closed",
          ""
        );
        return;
      }
      if (this.coordinatesCombined.length === 0) {
        if (this.tripStatus !== "COMPLETED") {
          this.showWarningAlert(
            "ERROR !",
            "error",
            `<p> No available location data yet. Try again later.</p>`,
            "Closed",
            ""
          );
        } else {
          this.showWarningAlert(
            "ERROR !",
            "error",
            `<p> No locations recorded. Check the device id (IMEI) if valid and active.</p>`,
            "Closed",
            ""
          );
        }
      }
    } else {
      if (this.tripStatus === "NEW") {
        this.showWarningAlert(
          "ERROR !",
          "error",
          `<p> Simulation not available. Start the trip to begin tracking.</p>`,
          "Closed",
          ""
        );
      } else {
        this.showErrorNotifIf();
      }
    }
    this.isPlayedShow = true;
    this.isPlayed = !this.isPlayed;

    if (type === "play") {
      if (
        this.playTrip?.length === this.coordinatesCombined?.length ||
        this.playTrip?.length === this.coordinatesCombined?.length + 1
      ) {
        this.playTrip = [];
      }
      if (this.coordinatesCombined?.length) {
        const start = this.playTrip.length ? this.playTrip.length - 1 : 0;
        const end = this.coordinatesCombined?.length;
        const time = 10000 - this.time;
        const points = this.coordinatesCombined?.slice(start, end);
        const center = points[2]?.split(",");
        if (center) {
          this.lat = center[0];
          this.lng = center[1];
        }
        this.simulated = false;
        this.makeTravelMarker(points, time);
      }
    } else {
      if (this.playTrip.length !== 0) {
        this.$destroyed.unsubscribe();
      }
    }
  }

  showErrorNotifIf() {
    if (this.coordinatesCombined.length === 0) {
      this.showWarningAlert(
        "ERROR !",
        "error",
        `<p> No available location data yet. Try again later.</p>`,
        "Closed",
        ""
      );
    }
  }

  showWarningAlert(title, type, description, closeTile?, actionTitle?) {
    let button;
    if (actionTitle) {
      button = {
        closeTile: closeTile,
        actionTitle: actionTitle,
      };
    }
    return this.dialog.open(WarningComponent, {
      panelClass: "notif-modal",
      data: {
        title: title,
        style: `${type}-snackbar`,
        description: description,
        icon: `xlog-${type}`,
        button: button,
      },
    });
  }

  /**
   * Travel Marker function
   *
   * @param {*} coordinates
   * @memberof TripDetailsComponent
   */
  async makeTravelMarker(coordinates, time) {
    this.markerPlay = [
      {
        latitude: coordinates[0].split(",")[0],
        longitude: coordinates[0].split(",")[1],
        icon: this.type == "container" ? this.containerIcon : this.truckIcon,
      },
    ];

    this.$destroyed = from(coordinates)
      .pipe(
        concatMap((coordinate) =>
          of(coordinate).pipe(delay(time / coordinates.length))
        )
      )
      .pipe(finalize(() => this.observableDone()))
      .subscribe((result: any) => {
        this.markerPlay[0].latitude = result.split(",")[0];
        this.markerPlay[0].longitude = result.split(",")[1];
        this.playTrip.push({
          latitude: parseFloat(result.split(",")[0]),
          longitude: parseFloat(result.split(",")[1]),
        });
        this.zoom = 16;
        this.lat = parseFloat(result.split(",")[0]);
        this.lng = parseFloat(result.split(",")[1]);
        this.time = time / this.coordinatesCombined?.length;
      });
  }

  observableDone() {
    setTimeout(() => {
      if (
        this.playTrip?.length === this.coordinatesCombined?.length ||
        this.playTrip?.length === this.coordinatesCombined?.length + 1
      ) {
        this.markerPlay = [];
        this.filterMarker = this.markers;
        this.simulated = true;
        this.isPlayed = false;
        this.time = 0;
        this.zoom = 10;
        this.setPlannedRouteAsCenter();
      }
    }, 1000);
  }

  setPlannedRouteAsCenter() {
    this.lat = parseFloat(`${this.originPlanned.lat}`);
    this.lng = parseFloat(`${this.originPlanned.lng}`);
  }

  getContainerArray() {
    if (Array.isArray(this.data?.tripDetails)) {
      if (this.type == "driver") {
        this.data?.tripDetails.map((container) => {
          this.containerInventory.push({
            actualDestinationLat: container.actualDestinationLat,
            actualDestinationLng: container.actualDestinationLng,
            actualOriginLat: container.actualOriginLat,
            actualOriginLng: container.actualOriginLng,
            actualStart: container.actualStart,
            actualTimeArival: container.actualTimeArival,
            actualTimeDeparture: container.actualTimeDeparture,
            actualStartLat: container.actualStartLat,
            actualStartLng: container.actualStartLng,
            ata: container.ata,
            atd: container.atd,
            containerImageUrl:
              container.containersInfo[0]?.containerNumber[0]
                ?.containerImageUrl,
            containersInfo: container.containersInfo,
            containerSize: container.containersInfo[0]?.containerSizeName,
            containerSizeId: container.containerSizeId,
            containerType: container.containersInfo[0]?.containerTypeName,
            containerTypeId: container.containerTypeId,
            destinationAddress: container.destinationAddress,
            destinationStateCityName: container.destinationStateCityName,
            loadTypeId: container.loadTypeId,
            loadTypeName: container.loadTypeName,
            qty: container.containersInfo[0]?.qty,
            originAddress: container.originAddress,
            schedule: container.schedule,
            scheduleEndDateTime: container.scheduleEndDateTime,
            scheduleStartDateTime: container.scheduleStartDateTime,
            status: container.status,
            trackingDeviceId:
              container.containersInfo[0]?.containerNumber[0].trackingDeviceId,
            sequenceNo: container.sequenceNo,
            driverName: container.driverAssigned[0].driverName,
            vehicle: { plateNumber: container.vehicleInfo[0].plateNumber },
            jobOrderNo: container.jobOrderNo,
            tripNo: container.tripNo,
            plateNumber: container.vehicleInfo[0].plateNumber,
          });
        });
        this.tripDetailsData = this.containerInventory;
        this.selectedTripDetails = this.containerInventory[0];
      } else {
        this.data.tripDetails.forEach((container) => {
          container?.containersInfo.forEach((item) => {
            item.containerNumber.forEach((num) => {
              this.containerInventory.push({
                actualDestinationLat: container.actualDestinationLat,
                actualDestinationLng: container.actualDestinationLng,
                actualOriginLat: container.actualOriginLat,
                actualOriginLng: container.actualOriginLng,
                actualStart: container.actualStart,
                actualTimeArival: container.actualTimeArival,
                actualTimeDeparture: container.actualTimeDeparture,
                actualStartLat: container.actualStartLat,
                actualStartLng: container.actualStartLng,
                ata: container.ata,
                atd: container.atd,
                containerImageUrl: num.containerImageUrl,
                containerNumber: num.containerNumber,
                destinationAddress: container.destinationAddress,
                destinationStateCityName: container.destinationStateCityName,
                originAddress: container.originAddress,
                schedule: container.schedule,
                scheduleEndDateTime: container.scheduleEndDateTime,
                scheduleStartDateTime: container.scheduleStartDateTime,
                status: container.status,
                trackingDeviceId:
                  container.containersInfo[0].containerNumber[0]
                    .trackingDeviceId,
                sequenceNo: container.sequenceNo,
                driverName: container.driverAssigned[0].driverName,
                vehicle: { plateNumber: container.vehicleInfo[0].plateNumber },
                jobOrderNo: container.jobOrderNo,
                tripNo: container.tripNo,
                plateNumber: container.vehicleInfo[0].plateNumber,
              });
            });
          });
        });
        this.tripDetailsData = this.containerInventory;
        this.selectedTripDetails = this.containerInventory[0];
      }
    } else {
      const data = {
        destinationAddress: this.data?.tripDetails?.ac?.destinationAddress,
        destinationStateCityName:
          this.data?.tripDetails?.ac?.destinationStateCityName,
        originAddress: this.data?.tripDetails?.ac?.originAddress,
        sequenceNo: this.data?.tripDetails?.ac?.sequenceNo,
        driverName: this.data?.tripDetails?.driver?.userName,
        vehicle: { plateNumber: this.data?.tripDetails?.vehicle?.plateNumber },
        jobOrderNo: this.data?.tripDetails?.jobOrderNo,
        acquiredTruckingServiceId:
          this.data?.tripDetails?.ac?.acquiredTruckingServiceId,
        tripNo: this.data?.tripDetails?.ac?.tripNo,
        actualTimeArival: this.data?.tripDetails?.ac?.actualTimeArival,
        actualTimeDeparture: this.data?.tripDetails?.ac?.actualTimeDeparture,
        plateNumber: this.data?.tripDetails?.vehicle?.plateNumber,
        actualStart: this.data?.tripDetails?.ac?.actualStart,
        containersInfo: this.data?.tripDetails?.containersInventory,
      };

      let selected = {
        ...this.data?.tripDetails?.containersInventory[0],
        ...data,
      };
      this.tripDetailsData.push(data);
      if (Array.isArray(this.data?.tripDetails)) {
        this.selectedTripDetails =
          this.data?.tripDetails?.containersInventory[0];
      } else {
        this.selectedTripDetails = selected;
      }
      this.containerInventory = this.data?.tripDetails?.containersInventory;
    }
    setTimeout(() => {
      this.containerNumber.setValue(this.containerInventory[0]);
    }, 500);
  }

  handleSelection(value, i) {
    if (this.isPlayedShow && value != this.selectedRoute) {
      if (this.playTrip.length !== 0) {
        this.$destroyed.unsubscribe();
      }
      this.playTrip = [];
      this.isPlayed = false;
    }
    this.getCombinedCoordinates(value);
    if (this.selectedOption === "driver") {
      this.selectedRoute = `ROUTE ${this.getGroup(i)}: ${
        value?.jobOrderNo || "--"
      } / ${value?.tripNo || "--"}`;
      let array = [];
      value.containersInfo.forEach((data) => {
        data.containerNumber.forEach((item) => {
          array.push(item);
        });
      });
      this.containerNumberArray = array;
      this.tripStatus = value?.status;
    } else {
      this.selectedContainerRoute = this.getGroup(value?.sequenceNo);
      this.trackingDeviceId = value?.trackingDeviceId;
      this.selectedContainer = `Container Number: ${
        value?.containerNumber || "--"
      } (IMEI: ${value?.trackingDeviceId || "--"})`;
      this.selectedContainerMobile = `Container Number: ${
        value?.containerNumber || "--"
      }`;
    }
    this.selectedTripDetails = this.containerInventory[i];

    this.isExpanded = !this.isExpanded;
  }

  selectedContainerNumber(event) {
    this.containerNumber.setValue(event?.value);
  }
  selectedContainerData(value) {
    this.selectedContainer = value;
    this.selectedContainerMobile = value;
  }

  getGroup(sequence) {
    return String.fromCharCode(sequence + 1 + 64);
  }

  openPopUp(data) {
    return this.dialog.open(PopUpComponent, {
      width: "20%",
      panelClass: "contact-form-dialog",
      data: {
        data: data.data,
        title: data.title,
        description: data.description,
        showDelete: data.showDelete,
      },
    });
  }
}
