import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatTableDataSource } from "@angular/material/table";
import { FuseSplashScreenService } from "@fuse/services/splash-screen.service";
import { FuseUtils } from "@fuse/utils";
import { AuditLogsService } from "app/services/audit-logs/audit-logs.service";
import * as moment from "moment";
import { NotificationComponent } from "../../notification/notification.component";
import { AuditLogsViewComponent } from "./audit-logs-view/audit-logs-view.component";

@Component({
  selector: "app-audit-logs",
  templateUrl: "./audit-logs.component.html",
  styleUrls: ["./audit-logs.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class AuditLogsComponent implements OnInit, OnChanges {
  @Input() title: any;
  @Input() filterOption: any;
  @Input() dataLength: any;
  @Input() auditLogs?: any;
  @Input() disabled?: any;
  @Output() handleAdvanceSearch = new EventEmitter();
  @Output() globalSearch = new EventEmitter();
  @Output() paginationAction = new EventEmitter();
  @Output() sortHandler: EventEmitter<any> = new EventEmitter();

  displayedColumns = [
    "createdOn",
    "module",
    "subModule",
    "action",
    "description",
    "createdBy",
    "actions",
  ];

  advanceSearchColumns = [
    "Date",
    "Module",
    "Submodule",
    "Action",
    "Description",
    "Username",
  ];

  options = {
    status: [
      { name: "Active", value: "1" },
      { name: "Inactive", value: "0" },
    ],
  };

  selectField = ["Status"];
  dateField = ["Date"];

  openAdvanceSearch = false;

  @ViewChild(`paginator`, { static: true })
  paginator: MatPaginator;
  dataSource: any = new MatTableDataSource([]);

  pageEvent: PageEvent;

  searchFilter = new FormControl();
  constructor(
    public dialog: MatDialog,
    private _fuseSplashScreenService: FuseSplashScreenService,
    private auditlogService: AuditLogsService,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this.auditLogs);
  }

  ngOnChanges(): void {
    this.dataSource = new MatTableDataSource(this.auditLogs);
  }

  AdvanceSearch(e) {
    this.handleAdvanceSearch.emit(e);
  }

  viewLogs(e) {
    this.dialog.open(AuditLogsViewComponent, {
      panelClass: "audit-logs-view",
      data: {
        title: "View Audit Logs",
        value: e,
      },
    });
  }
  handlePage(event) {
    this.filterOption.pageSize = event.pageSize;
    this.filterOption.pageNumber = event.pageIndex;
    this.paginationAction.emit(this.filterOption);
  }

  search() {
    this.filterOption = {
      ...this.filterOption,
      pageNumber: 0,
      quickSearch: this.searchFilter.value.trim(),
    };

    this.globalSearch.emit(this.filterOption);
  }

  /**
   * Date Converter with Time
   *
   * @param {*} date
   * @return {*}
   * @memberof ReceivableDetailsComponent
   */
  plainConvertDateToLocal(date) {
    return FuseUtils.plainConvertDateToLocal(date, "MMM DD, YYYY hh:mm:ss a");
  }

  download(e) {
    if (this.filterOption.subModule == "company") {
      this.auditlogService.getCompanyAuditLogs(this.filterOption, e).subscribe(
        (res) => {
          if (res) {
            const link = document.createElement("a");
            let fileType;
            if (e === "csv") {
              fileType = window.URL.createObjectURL(
                new Blob([res], { type: "text/csv" })
              );
            } else {
              fileType = window.URL.createObjectURL(
                new Blob([res], {
                  type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                })
              );
            }
            link.href = fileType;
            link.download =
              "Audit_Logs_Listing" +
              new Date().toString() +
              `.${e === "csv" ? "csv" : "xlsx"}`;
            link.click();
          }
        },
        (error) => {
          let data = FuseUtils.errorHandler(error);
          this.showSnackbar("ERROR !", "error", data);
        }
      );
    } else if (
      this.filterOption.subModule == "user" &&
      this.filterOption.module != "Access Management"
    ) {
      this.auditlogService.getUserAuditLogs(this.filterOption, e).subscribe(
        (res) => {
          if (res) {
            const link = document.createElement("a");
            let fileType;
            if (e === "csv") {
              fileType = window.URL.createObjectURL(
                new Blob([res], { type: "text/csv" })
              );
            } else {
              fileType = window.URL.createObjectURL(
                new Blob([res], {
                  type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                })
              );
            }
            link.href = fileType;
            link.download =
              "Audit_Logs_Listing" +
              new Date().toString() +
              `.${e === "csv" ? "csv" : "xlsx"}`;
            link.click();
          }
        },
        (error) => {
          let data = FuseUtils.errorHandler(error);
          this.showSnackbar("ERROR !", "error", data);
        }
      );
    } else {
      this.auditlogService.getAuditLogsDownLoad(this.filterOption, e).subscribe(
        (res) => {
          if (res) {
            const link = document.createElement("a");
            let fileType;
            if (e === "csv") {
              fileType = window.URL.createObjectURL(
                new Blob([res], { type: "text/csv" })
              );
            } else {
              fileType = window.URL.createObjectURL(
                new Blob([res], {
                  type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                })
              );
            }
            link.href = fileType;
            link.download =
              "Audit_Logs_Listing" +
              new Date().toString() +
              `.${e === "csv" ? "csv" : "xlsx"}`;
            link.click();
          }
        },
        (error) => {
          let data = FuseUtils.errorHandler(error);
          this.showSnackbar("ERROR !", "error", data);
        }
      );
    }
  }

  showSnackbar(title, type, description) {
    this._snackBar.openFromComponent(NotificationComponent, {
      panelClass: [`${type}-snackbar`],
      verticalPosition: "top",
      horizontalPosition: "end",
      duration: 3000,
      data: {
        title: title,
        description: description,
        icon: `xlog-${type}`,
      },
    });
  }

  sortData(e: any) {
    this.filterOption.isDescending =
      e.direction === "" ? null : e.direction === "asc" ? false : true;
    this.filterOption.orderBy = e.active;
    // this.getCompanyListing();
    this.sortHandler.emit(this.filterOption);
  }
}
