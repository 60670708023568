import { Action } from "@ngrx/store";
import { UserInformation } from "app/core/models/user-info.model";

export enum UserInformationActionTypes {
  SetUserInformation = "[UserInformation] Set UserInformation",
  RemoveUserInformation = "[UserInformation] Remove UserInformation",
}

export class SetUserInformation implements Action {
  readonly type = UserInformationActionTypes.SetUserInformation;
  constructor(public payload: UserInformation) {}
}

export class RemoveUserInformation implements Action {
  readonly type = UserInformationActionTypes.RemoveUserInformation;
  constructor() {}
}

export type UserInformationActions = SetUserInformation | RemoveUserInformation;
