import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ProtectedImagePipe } from "./protected-image.pipe";
import { SafePipe } from "./safe.pipe";
import { FileValidationPipe } from "./file-validation.pipe";

@NgModule({
  declarations: [ProtectedImagePipe, SafePipe, FileValidationPipe],
  imports: [CommonModule],
  exports: [ProtectedImagePipe, SafePipe, FileValidationPipe],
})
export class ProtectedImageModule {}
