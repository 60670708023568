import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-emergency-trip",
  templateUrl: "./emergency-trip.component.html",
  styleUrls: ["./emergency-trip.component.scss"],
})
export class EmergencyTripComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
