import { Component, OnInit } from "@angular/core";

@Component({
  selector: "legends-map",
  templateUrl: "./legends-map.component.html",
  styleUrls: ["./legends-map.component.scss"],
})
export class LegendsMapComponent implements OnInit {
  items: object[] = [];

  constructor() {}

  ngOnInit(): void {
    this.items = [
      {
        title: "Pending",
        imgUrl: "assets/icons/legend/Pending.png",
        imgClass: "p-24",
        toolTip:
          "Origin / Destination location which are still pending for fulfillment.",
      },
      {
        title: "Start Trip",
        imgUrl: "assets/icons/legend/Start Trip.png",
        imgClass: "p-24",
        toolTip:
          'Location of Driver App when the "Start" button was triggered.',
      },
      {
        title: "Origin",
        imgUrl: "assets/icons/legend/Origin.png",
        imgClass: "p-24",
        toolTip:
          'Confirmed fulfillment at Origin. For trucking, this is the location of the Driver App when the "Arrive" button was triggered. For Sea, this is the departure of the vessel from the origin.',
      },
      {
        title: "Transhipment",
        imgUrl: "assets/icons/legend/Transhipment - Destination.png",
        imgClass: "p-24",
        toolTip:
          'Confirmed fulfillment at Transhipment / Destination. For Trucking, this is the location of the Driver App when the "End Trip" button was triggered. For Sea, this is the arrival of the vessel at transhipment and/or the destination.',
      },
      {
        title: "Driver App",
        imgUrl: "assets/icons/legend/Driver App@2x.png",
        imgClass: "p-8",
        toolTip: "Current location of the Driver App during fulfillment.",
      },
      {
        title: "Driver App SOS",
        imgUrl: "assets/icons/legend/Driver App SOS@2x.png",
        imgClass: "p-8",
        toolTip:
          'Location of the Driver App when the "SOS/Emergency" button was triggered.',
      },
      {
        title: "Container IoT",
        imgUrl: "assets/icons/legend/Container IoT@2x.png",
        imgClass: "p-8",
        toolTip:
          "Current or Last Known location of the container based on the latest data provided by the attached IoT device. Accuracy may depend on the availability of cell towers, Wi-Fi hotspots, and/or GPS lock.",
      },
      {
        title: "Vessel",
        imgUrl: "assets/icons/legend/Vessel@2x.png",
        imgClass: "p-8",
        toolTip:
          "Last known location of the vessel based on its available IMO/MMSI.",
      },
      {
        title: "Unavailable Mobile Location",
        imgUrl: "assets/icons/legend/Unavailable Mobile Location.png",
        imgClass: "p-32",
        toolTip:
          "Point of Disconnection and Point of Reconnection of a mobile phone's GPS to the app. This may be due to the mobile phone's automatic closure of apps that are idle or in sleep mode therefore discontinuing the use of it's location services until app is re-opened.",
      },
      {
        title: "Actual Transverse",
        imgUrl: "assets/icons/legend/Actual Traverse@2x.png",
        imgClass: "p-custom",
        toolTip: "Driver App's point to point traverse during a trip.",
      },
      {
        title: "Previous Container Location",
        imgUrl: "assets/icons/legend/Previous Container Location.png",
        imgClass: "p-28",
        toolTip:
          "Historical location points of a container based on the reported long/lat from the container IoT.",
      },
    ];
  }
}
