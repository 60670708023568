<div id="warning">
  <div [id]="data?.style">
    <div fxLayout="column" fxLayoutAlign="start" fxLayoutGap="10px">
      <div fxLayoutAlign="start" fxFlex="1 0 auto">
        <div fxFlex="10" fxLayoutAlign="start">
          <mat-icon [svgIcon]="data?.icon"></mat-icon>
        </div>
        <div fxFlex fxLayout="column" fxLayoutGap="10px">
          <div class="font-weight-900">{{ data?.title }}</div>
          <div [innerHTML]="data?.description"></div>
        </div>
      </div>
      <div
        fxLayoutAlign="end"
        class="p-8"
        *ngIf="data?.button"
        fxLayoutGap="10px"
      >
        <button
          mat-button
          class="closeTile"
          *ngIf="data.button.closeTile"
          (click)="action(data?.button?.closeTile)"
        >
          {{ data?.button?.closeTile }}
        </button>
        <button
          *ngIf="data?.button?.actionTitle"
          mat-button
          class="buttonAction"
          (click)="action(data?.button?.actionTitle)"
        >
          {{ data?.button?.actionTitle }}
        </button>
      </div>
    </div>
  </div>
</div>
