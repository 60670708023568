<div
  mat-button
  class="accept-dropdown-content border border-radius-4 h-100-p"
  fxLayout="column"
  fxLayoutAlign="start stretch"
>
  <div
    class="accent-500 py-8 px-12 cursor-pointer"
    fxLayout="row"
    fxLayoutAlign="end center"
    fxLayoutGap="4px"
    (click)="close()"
  >
    <mat-icon> close </mat-icon>
  </div>
  <div
    class="fuse-white p-16 overflow-y-auto"
    fxLayout="column"
    fxLayoutAlign="space-between stretch"
    fxLayoutGap="16px"
    fxFlex
  >
    <ng-container *ngIf="!isImage; else showImg">
      <iframe
        [src]="file | protectedImage | async | safe: 'resourceUrl'"
        width="100%"
        height="500px"
        title="file"
      >
      </iframe>
    </ng-container>
    <ng-template #showImg>
      <picture>
        <img [src]="file | protectedImage | async" alt="" />
      </picture>
    </ng-template>

    <div
      fxLayout="row"
      fxLayoutAlign="flex-end"
      class="pr-16 pb-24"
      fxLayoutGap="10px"
    >
      <button
        (click)="close()"
        mat-flat-button
        class="mat-elevation-z3 grey-600"
      >
        <mat-icon>clear</mat-icon>Cancel
      </button>
    </div>
  </div>
</div>
