import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { FuseUtils } from "@fuse/utils";
import { environment } from "environments/environment";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AuditLogsService {
  constructor(private http: HttpClient) {}

  getAuditLogsPerModule(query): Observable<any> {
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");

    return this.http.get<any>(
      `${environment.globalCMSApi}/global/cms/api/v1/audit-data-log/list`,
      { params: FuseUtils.FormatQuery(query), headers: headers }
    );
  }

  getCompaniesAuditLogs(query): Observable<any> {
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");

    return this.http.get<any>(
      `${environment.clientManagement}/clients/api/v1/logs/${query.subModule}/${query.keyFieldId}/filter`,
      { params: FuseUtils.FormatQuery(query), headers: headers }
    );
  }

  getUsersAuditLogs(query): Observable<any> {
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json");

    return this.http.get<any>(
      `${environment.clientManagement}/clients/api/v1/logs/${query.subModule}/${query.keyFieldId}/filter`,
      { params: FuseUtils.FormatQuery(query), headers: headers }
    );
  }

  getAuditLogsDownLoad(query, type): Observable<any> {
    let params = new HttpParams();
    params = params.append("pageNumber", query.pageNumber);
    params = params.append("pageSize", query.pageSize);
    if (query.module) {
      params = params.append("module", query.module);
    }
    if (query.subModule) {
      params = params.append("subModule", query.subModule);
    }
    if (query.keyFieldId?.toString()) {
      params = params.append("keyFieldId", query.keyFieldId);
    }

    for (const keyProp in query.query) {
      if (query.query[keyProp]) {
        params = params.append(keyProp, query.query[keyProp]);
      }
    }

    if (query.quickSearch) {
      params = params.append("quickSearch", query.quickSearch);
    }

    if (query.origin) {
      params = params.append("origin", query.origin);
    }
    let headers = new HttpHeaders({
      Accept: type === "csv" ? "text/csv" : "",
    });
    let responseType = `${type === "csv" ? "text" : "blob"}` as "json";

    return this.http.get<any>(
      `${environment.globalCMSApi}/global/cms/api/v1/audit-data-log/list/${type}`,
      { params: params, headers, responseType: responseType }
    );
  }

  getCompanyAuditLogs(query, type): Observable<any> {
    let headers = new HttpHeaders({
      Accept: type === "csv" ? "text/csv" : "",
    });
    let responseType = `${type === "csv" ? "text" : "blob"}` as "json";

    return this.http.get<any>(
      `${environment.clientManagement}/clients/api/v1/logs/company/${query.keyFieldId}/filter/download`,
      {
        params: FuseUtils.FormatQuery(query),
        headers,
        responseType: responseType,
      }
    );
  }

  getUserAuditLogs(query, type): Observable<any> {
    let params = new HttpParams();
    params = params.append("pageNumber", query.pageNumber);
    params = params.append("pageSize", query.pageSize);
    params = params.append("fileType", type);

    if (query.module) {
      params = params.append("module", query.module);
    }
    if (query.subModule) {
      params = params.append("subModule", query.subModule);
    }
    if (query.keyFieldId?.toString()) {
      params = params.append("keyFieldId", query.keyFieldId);
    }

    for (const keyProp in query.query) {
      if (query.query[keyProp]) {
        params = params.append(keyProp, query.query[keyProp]);
      }
    }

    if (query.quickSearch) {
      params = params.append("quickSearch", query.quickSearch);
    }

    if (query.origin) {
      params = params.append("origin", query.origin);
    }
    let headers = new HttpHeaders({
      Accept: type === "csv" ? "text/csv" : "",
    });
    let responseType = `${type === "csv" ? "text" : "blob"}` as "json";

    return this.http.get<any>(
      `${environment.clientManagement}/clients/api/v1/logs/user/${query.keyFieldId}/filter/download`,
      { params: params, headers, responseType: responseType }
    );
  }
}
