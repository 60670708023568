import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "fileValidation",
})
export class FileValidationPipe implements PipeTransform {
  transform(value: string, ...args: unknown[]): unknown {
    if (value) {
      if (value.includes(".pdf")) {
        return "";
      } else {
        return "image/png";
      }
    }
  }
}
