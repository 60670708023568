<!-- <p>advance-search works!</p>
<button type="button" (click)="handleSearch.emit('noice')"> Click to trigger event </button> -->
<div id="advance-search">
  <div class="container">
    <div fxLayout="column" fxLayoutAlign="start start" class="p-8 w-100-p">
      <div
        fxLayout="row"
        fxLayout.xs="column"
        fxLayoutAlign="space-between start"
        class="p-24 w-100-p border-white-top"
      >
        <div>
          <div class="title">ADVANCED SEARCH</div>
          <div class="font-size-10">
            Choose filters that will be applied to all queries in the table.
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="8px">
          <div>
            <button
              mat-button
              class="accent-500"
              type="button"
              (click)="reset()"
            >
              Reset Filter
            </button>
          </div>
          <div>
            <button
              mat-button
              class="accent-500"
              type="button"
              (click)="search()"
            >
              Search
            </button>
          </div>
        </div>
      </div>
      <!-- fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="8px"  -->
      <form
        *ngIf="columns && form"
        class="border-white-bot p-24 w-100-p"
        [formGroup]="form"
      >
        <div
          fxLayout="row"
          fxLayout.xs="column"
          fxLayoutGap.xs="10px"
          fxLayoutAlign="start start"
          class="flex-wrap h-full"
        >
          <div
            *ngFor="let col of columns; let i = index"
            class="mr-4 input-field mb-4"
            [ngClass]="{
              default: screenSize > 'xl',
              xl: screenSize === 'xl',
              lg: screenSize === 'lg',
              md: screenSize === 'md',
              sm: screenSize === 'sm',
              xsm: screenSize === 'xsm',
              sm: screenSize === 'sm'
            }"
            ngClass.lt-sm="mobile"
          >
            <div
              [id]="i"
              fxLayout="row"
              fxLayoutAlign="start center"
              fxLayoutGap="2px"
              class="w-100-p bg-white mr-4 text-truncate min-height-57"
            >
              <ng-container
                *ngIf="!checkIfSelectField(col) && !checkIfDate(col)"
              >
                <div
                  fxLayout="column"
                  fxLayoutAlign="start start"
                  fxLayoutGap="2px"
                >
                  <mat-label>
                    {{ getCol(col) }}
                  </mat-label>
                  <input
                    matInput
                    [id]="i"
                    [formControlName]="getFormControl(col)"
                    [disabled]="checkDisabled(col)"
                  />
                </div>
              </ng-container>
              <ng-container *ngIf="checkIfSelectField(col)">
                <div
                  fxLayout="column"
                  fxLayoutAlign="start start"
                  fxLayoutGap="2px"
                  class="w-100-p"
                >
                  <mat-label>{{ col }}</mat-label>
                  <mat-select
                    class="color-table"
                    [formControlName]="getFormControl(col)"
                  >
                    <mat-option
                      *ngFor="let opt of getOptions(col)"
                      [value]="opt.value"
                    >
                      {{ opt.name }}
                    </mat-option>
                  </mat-select>
                </div>
              </ng-container>
              <ng-container *ngIf="checkIfDate(col)">
                <div
                  fxLayout="column"
                  fxLayoutAlign="start start"
                  fxLayoutGap="2px"
                >
                  <mat-label class="nowrap">{{ col }}</mat-label>
                  <ng-container *ngIf="isSingle; else multiple">
                    <div fxLayout="row" class="singleCalendar">
                      <input
                        matInput
                        [formControlName]="getFormControl(col)"
                        [matDatepicker]="picker2"
                      />
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="picker2"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #picker2 color="primary"></mat-datepicker>
                    </div>
                  </ng-container>
                  <ng-template #multiple>
                    <div fxLayout="row">
                      <input
                        matInput
                        type="text"
                        name="daterange"
                        [formControlName]="getFormControl(col)"
                        ngxDaterangepickerMd
                        [alwaysShowCalendars]="true"
                        [showClearButton]="true"
                        [placeholder]="'Select Date'"
                        [keepCalendarOpeningWithRange]="true"
                        readonly
                        class="form-control"
                      />
                    </div>
                  </ng-template>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
