<div id="reset-password" class="inner-scroll" fxLayout="row" fxLayoutAlign="center center">

    <div id="reset-password-form-wrapper" fusePerfectScrollbar>

        <div id="reset-password-form">

            <div class="logo2" >
                <img src="assets/icons/logos/xlog-logo-2x.png">
            </div>

            <div class="title">CHANGE YOUR PASSWORD</div>

            <form name="resetPasswordForm" [formGroup]="resetPasswordForm" novalidate>

                <div fxLayout="row" fxLayoutGap="10px">
                    <div fxLayout="column" fxFlex>
                      <mat-form-field floatLabel="always" fxFlex class="px-8">
                        <mat-label>Current Password:</mat-label>
                        <input
                        autocomplete="randomtext"
                        matInput
                        fxFlex
                        required
                        formControlName="currentPassword"
                        [type]="Currenthide ? 'password' : 'text'"
                        />
                        <mat-icon matSuffix (click)="Currenthide = !Currenthide">{{
                        Currenthide ? "visibility_off" : "visibility"
                        }}</mat-icon>
                        <mat-error
                          *ngIf="
                          resetPasswordForm.get('currentPassword').hasError('required')
                          "
                        >
                        Current Password is required
                        </mat-error>
                        <!-- <mat-error
                          *ngIf="
                          changePasswordForm.get('pinCode').hasError('minlength')
                          "
                        >
                        Current Password must be at least 4 characters
                        </mat-error> -->
                      </mat-form-field>
                    </div>
                  </div>
                  <div fxLayout="row" fxLayoutGap="10px">
                    <div fxLayout="column" fxFlex>
                      <mat-form-field floatLabel="always" fxFlex class="mt-4 px-8">
                        <mat-label>New Password</mat-label>
                        <input
                          autocomplete="randomtext"
                          matInput #password
                          [type]="hide ? 'password' : 'text'"
                          fxFlex
                          required
                          formControlName="password"
                        />
                        <mat-icon matSuffix (click)="hide = !hide">{{
                          hide ? "visibility_off" : "visibility"
                        }}</mat-icon>
                        
                      <mat-error
                      *ngIf="
                      resetPasswordForm.get('password').hasError('minlength')
                      "
                    >
                      New Password must be at least 8 characters
                    </mat-error>
                    <mat-error
                      *ngIf="
                      resetPasswordForm.get('password').hasError('required')
                      "
                    >
                     New Password is required
                    </mat-error>
                    
                    <mat-error *ngIf="resetPasswordForm.get('password').hasError('passwordsNotMatching')">
                      Password does not match
                    </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
    
                  <div *ngIf="showPasswordValid" id="content" class="mt-4 mb mb-12 px-8">
                    <mat-password-strength #passwordComponent
                        (onStrengthChanged)="onStrengthChanged($event)"
                        [password]="password.value">
                    </mat-password-strength>
                            <!--Password's strength info-->
                    <mat-password-strength-info
                        [passwordComponent]="passwordComponent">
                    </mat-password-strength-info>
                  </div>
    
                  <div fxLayout="row" fxLayoutGap="10px">
                    <div fxLayout="column" fxFlex>
                      <mat-form-field floatLabel="always" fxFlex class="mt-4 px-8">
                        <mat-label>Confirm New Password</mat-label>
                        <input
                          matInput
                          autocomplete="off"
                          [type]="confirmHide ? 'password' : 'text'"
                          fxFlex
                          required
                          formControlName="passwordConfirm"
                        />
                        <mat-icon matSuffix (click)="confirmHide = !confirmHide">{{
                          confirmHide ? "visibility_off" : "visibility"
                        }}</mat-icon>
                        <mat-error
                        *ngIf="
                        resetPasswordForm
                            .get('passwordConfirm')
                            .hasError('required')
                        "
                      >
                        New Password confirmation is required
                      </mat-error>
                      <mat-error
                        *ngIf="
                          !resetPasswordForm
                            .get('passwordConfirm')
                            .hasError('required') &&
                            resetPasswordForm
                            .get('passwordConfirm')
                            .hasError('passwordsNotMatching')
                        "
                      >
                      Password does not match
                      </mat-error>
                      <mat-error
                        *ngIf="
                          !resetPasswordForm
                            .get('passwordConfirm')
                            .hasError('passwordsNotMatching') &&
                          !resetPasswordForm
                            .get('passwordConfirm')
                            .hasError('required') &&
                            resetPasswordForm
                            .get('passwordConfirm')
                            .hasError('minlength')
                        "
                      >
                        New Password Confirm must be at least 8 characters
                      </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
      
                  <!-- <div class="px-4" fxLayout="column" fxLayoutGap="10px">
                    <button
                    mat-raised-button
                    class="mt-4 p-8"
                    [disabled]="resetPasswordForm.invalid"
                    (click)="changePass()"
                    [ngClass]="{
                      'submit-button': resetPasswordForm?.valid,
                      'cancel-button': !resetPasswordForm.valid
                    }"
                  >
                    <ng-container> Save New Password </ng-container>
                  </button>
                  </div> -->
                  <div class="px-8">
                      <button mat-raised-button
                      class="w-100-p mt-12"
                      [ngClass]="{
                        'submit-button': resetPasswordForm?.valid,
                        'cancel-button': !resetPasswordForm.valid
                      }"
                              aria-label="CHANGE MY PASSWORD" (click)="submit()" [disabled]="resetPasswordForm.invalid">
                          SAVE
                      </button>
                  </div>
            </form>
        </div>
    </div>
</div>