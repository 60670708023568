<div class="simulator-legend-container">
  <div class="border-bottom pb-12">
    <header class="">Legends</header>
  </div>
  <div class="overflow-y">
    <div class="legend-group">
      <ng-container *ngFor="let item of items">
        <div class="legend-item">
          <div class="icon-container" [ngClass]="item.imgClass">
            <img class="legend-icon" [src]="item.imgUrl" />
          </div>
          <p>
            {{ item.title }}
            <img
              class="Info-Icon"
              src="assets/icons/legend/Info-Icon.png"
              [matTooltip]="item.toolTip"
            />
          </p>
        </div>
      </ng-container>
      <div class="legend-item hidden">
        <div class="icon-container">
          <img />
        </div>
        <p>
          <img />
        </p>
      </div>
    </div>
  </div>
</div>
