import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { FuseUtils } from "@fuse/utils";
import { DocumentUploader } from "../golbal.model";

@Component({
  selector: "file-uploader",
  templateUrl: "./file-uploader.component.html",
  styleUrls: ["./file-uploader.component.scss"],
})
export class FileUploaderComponent implements OnInit, OnChanges {
  @Input() incomingData: DocumentUploader;
  @Input() index: any;
  @Output() outgoingData: EventEmitter<any> = new EventEmitter();

  filesUploaded = [];
  lastDocument: boolean = false;
  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.incomingData && changes?.incomingData?.currentValue) {
      this.incomingData.documents =
        changes?.incomingData?.currentValue?.documents;
      this.prepopulateDocuments();
    }
  }

  ngOnInit(): void {}

  prepopulateDocuments() {
    if (this.incomingData?.isFormArray) {
      this.incomingData?.documents.forEach((element, i) => {
        if (this.index === i) {
          this.filesUploaded = [];
          if (element) {
            this.uploadDocument(element.file);
          } else {
            this.onRemove(i);
          }
        }
      });
    } else {
      this.incomingData?.documents.forEach((element, i) => {
        if (element) {
          this.uploadDocument(element.file);
        }
      });
    }
  }

  onSelect(event): void {
    let document = [];
    if (this.incomingData?.isSingleUploader) {
      // single
      this.filesUploaded = [];
      document.push(event.addedFiles[0]);
    } else {
      document = event.addedFiles;
    }
    document.forEach((file) => {
      this.setImages(file);
    });
  }

  setImages(file): void {
    if (file.length !== 0) {
      this.uploadDocument(file);
    }
  }

  uploadDocument(file) {
    let fileType = FuseUtils.fileType(file);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (_event) => {
      let document = {
        file: file,
        type: fileType,
        name: file.name,
        mime: file.type,
        base64: _event.target.result,
      };
      this.filesUploaded.push(document);
      this.output(document);
    };
  }

  onRemove(i): void {
    if (this.incomingData?.isFormArray) {
      this.filesUploaded.splice(0, 1);
    } else {
      this.filesUploaded.splice(i, 1);
    }
    this.output(null);
  }

  output(data) {
    if (this.incomingData?.isSingleUploader) {
      this.incomingData?.isFormArray
        ? this.outgoingData.emit(data)
        : this.outgoingData.emit(this.filesUploaded);
    } else {
      this.outgoingData.emit(this.filesUploaded);
    }
  }
}
