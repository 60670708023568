import { Token } from "app/core/models/token.model";
import { TokenActions, TokenActionTypes } from "./token.action";

export const initialState: Token = null;
export function reducer(state = initialState, action: TokenActions): Token {
  switch (action.type) {
    case TokenActionTypes.SetToken:
      state = action.payload;
      return state;
    case TokenActionTypes.RemoveToken:
      state = initialState;
      return state;
    default:
      return state;
  }
}
