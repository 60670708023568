import { Injectable, Injector } from "@angular/core";
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpErrorResponse,
  HttpResponse,
} from "@angular/common/http";
import { Observable, Subject, throwError } from "rxjs";
import {
  catchError,
  finalize,
  map,
  switchMap,
  takeUntil,
} from "rxjs/operators";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { RootState } from "../store";
import { AuthService } from "app/services/auth/auth.service";
import { SetToken } from "../store/token/token.action";

@Injectable({
  providedIn: "root",
})
export class GlobalHttpInterceptorService implements HttpInterceptor {
  currentRequestCount = 0;

  constructor(
    public router: Router,
    private store: Store<RootState>,
    private _authService: AuthService
  ) { }

  /**
   * This Injects the Access Token and ID Token to Bearer
   *
   * @param {HttpRequest<any>} request
   * @return {*}
   * @memberof GlobalHttpInterceptorService
   */
  injectToken(request: HttpRequest<any>) {
    let token =
      localStorage.getItem("token") != "null"
        ? localStorage.getItem("token")
        : null;

    if (token && token != null) {
      let parsedToken = JSON.parse(token);
      const { idToken, tokenType } = parsedToken;
      return request.clone({
        setHeaders: {
          Authorization: `${tokenType} ${idToken}`,
        },
      });
    }
    return request.clone({});
  }

  /**
   * Intercepts API Call
   *
   * @param {HttpRequest<any>} req
   * @param {HttpHandler} next
   * @return {*}  {Observable<HttpEvent<any>>}
   * @memberof GlobalHttpInterceptorService
   */
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.currentRequestCount++; //bilang ng request
    return next.handle(this.injectToken(req)).pipe(
      catchError((error: HttpErrorResponse) => {
        let handled: boolean = false;
        switch (error.status) {
          case 500:
            return throwError("Something went wrong !");

          case 401:
            let token = localStorage.getItem("refreshToken");
            if (!token) {
              this.router.navigate(["/auth/login"], {
                queryParams: {
                  expired: true,
                },
              });
              return throwError("Error, Session Expired");
            }

            if (error.url.includes("refresh")) {
              this.router.navigate(["/auth/login"], {
                queryParams: {
                  expired: true,
                },
              });
              return throwError("Error, Session Expired");
            } else {
              return this._authService
                .generateNewToken({
                  refreshToken: token,
                })
                .pipe(
                  switchMap((res: any) => {
                    let { authenticationResult } = res && res.data;

                    const { refreshToken, idToken, tokenType, accessToken } =
                      authenticationResult;

                    this.store.dispatch(
                      new SetToken({
                        refreshToken,
                        idToken,
                        tokenType,
                        accessToken,
                      })
                    );

                    localStorage.setItem(
                      "token",
                      JSON.stringify({
                        refreshToken,
                        idToken,
                        tokenType,
                        accessToken,
                      })
                    );

                    return next.handle(this.injectToken(req));
                  })
                );
            }
        }

        if (!handled) {
          return throwError(error.error);
        }
      }),
      finalize(() => {
        this.currentRequestCount--;
        if (this.currentRequestCount === 0) {
          // hide loading spinner if all requests are done
        }
      })
    );
  }
}
