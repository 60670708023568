import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(private http: HttpClient) { }

  /**
   * Calls Sign In API - Returns User Details and Access Tokens
   *
   * @param {*} { username, password }
   * @return {*}  {Observable<any>}
   * @memberof AuthService
   */
  signInUser({ username, password }): Observable<any> {
    return this.http.post<any>(
      `${environment.globalCMSApi}/global/cms/api/v1/sign-in`,
      {
        username,
        password,
      }
    );
  }

  /**
   * Generates New Token
   *
   * @param {*} { refreshToken }
   * @return {*}  {Observable<any>}
   * @memberof AuthService
   */
  generateNewToken({ refreshToken }): Observable<any> {
    return this.http.get<any>(
      `${environment.globalCMSApi}/global/cms/api/v1/token/refresh`,
      {
        headers: {
          "refresh-token": `${refreshToken}`,
        },
      }
    );
  }

  /**
   * Requests New Password
   *
   * @param {*} { emailAddress }
   * @return {*}  {Observable<any>}
   * @memberof AuthService
   */
  requestForgotPassword({ emailAddress }): Observable<any> {
    return this.http.post<any>(
      `${environment.globalCMSApi}/global/cms/api/v1/forgot-password`,
      {},
      {
        params: {
          emailAddress,
        },
      }
    );
  }

  /**
   * Logout
   *
   * @param {*} { refreshToken }
   * @return {*}  {Observable<any>}
   * @memberof AuthService
   */
  logout(refreshToken): Observable<any> {
    return this.http.get<any>(
      `${environment.globalCMSApi}/global/cms/api/v1/signout`,
      {
        headers: {
          accessToken: refreshToken,
        },
      }
    );
  }

  /**
   * Setup New Account
   *
   * @param {*} payload
   * @return {*}  {Observable<any>}
   * @memberof AuthService
   */
  setupAccount(payload): Observable<any> {
    return this.http.post<any>(
      `${environment.globalCMSApi}/global/cms/api/v1/set-username-and-password`,
      payload
    );
  }

  /**
   * Change Password
   *
   * @param {*} payload
   * @return {*}  {Observable<any>}
   * @memberof AuthService
   */
  changePassword(payload): Observable<any> {
    return this.http.post<any>(
      `${environment.globalCMSApi}/global/cms/api/v1/change-password`,
      payload
    );
  }

  /**
   * Generate Pin
   *
   * @param {*} token
   * @return {*}  {Observable<any>}
   * @memberof AuthService
   */
  generatePin(token): Observable<any> {
    return this.http.post<any>(
      `${environment.globalCMSApi}/global/cms/api/v1/generate-pin/${token}`,
      {}
    );
  }

  /**
   * Change password internally from my profile
   *
   * @param {*} payload
   * @return {*}  {Observable<any>}
   * @memberof AuthService
   */
  profileChangePassword(payload, token, bearer): Observable<any> {
    return this.http.post<any>(
      `${environment.globalCMSApi}/global/cms/api/v1/change-password-internal`, payload, {
      headers: {
        accessToken: token
      }
    }
    );
  }
}
