import { UserInformation } from "app/core/models/user-info.model";
import {
  UserInformationActions,
  UserInformationActionTypes,
} from "./user-info.action";

export const initialState: UserInformation = null;
export function reducer(
  state = initialState,
  action: UserInformationActions
): UserInformation {
  switch (action.type) {
    case UserInformationActionTypes.SetUserInformation:
      state = action.payload;
      return state;
    case UserInformationActionTypes.RemoveUserInformation:
      state = initialState;
      return state;
    default:
      return state;
  }
}
