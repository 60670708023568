import { Component, Inject, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { FuseUtils } from "@fuse/utils";
import { CountrySettingsService } from "app/services/country-settings/country-settings.service";
import { NotificationComponent } from "../notification/notification.component";

@Component({
  selector: "app-tax-modal",
  templateUrl: "./tax-modal.component.html",
  styleUrls: ["./tax-modal.component.scss"],
})
export class TaxModalComponent implements OnInit {
  taxForm: FormGroup;
  data: any;
  taxTypes: any = [];
  constructor(
    public dialogRef: MatDialogRef<TaxModalComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private countryService: CountrySettingsService,
    private _formBuilder: FormBuilder,
    private _snackBar: MatSnackBar
  ) {
    this.data = _data;
  }

  ngOnInit(): void {
      this.taxForm = this._formBuilder.group({
        taxTypeCode: [this.data?.data?.taxTypeCode, Validators.required],
        taxTypeDescription: [this.data?.data?.taxTypeDescription],
        taxPercent: [
          this.data?.data?.taxPercent,
          [
            Validators.required,
            Validators.min(1),
            Validators.max(100),
            Validators.pattern( /^\d+\.?\d{0,2}$/),
            Validators.maxLength(5),
          ],
        ],
        taxExemption: [this.data?.data?.taxExemption ? 'Yes' : 'No' || '',],
      });
    this.getTax();
  }

  getTax(){
    this.countryService.getCountryandTax().subscribe((res)=>{
      if(res.isSuccessful){
        const { taxTypes } = res.data[0]

        this.taxTypes = taxTypes
        if(this.data.type=== "Save"){
          if(this.data.dataSource){
            let val = this.taxTypes.filter(el=>(!this.data.dataSource.some(element=>element.taxTypeDescription===el.description && (element.taxTypeDescription!==this.data.data.taxTypeDescription))) )
            this.taxTypes = val
          }
        }else{
          if(this.data.dataSource){
            let val = this.taxTypes.filter(el=>!this.data.dataSource.some(element=>element.taxTypeDescription===el.description))
            this.taxTypes = val
          }
        }

      }
    },error=>{
      let data = FuseUtils.errorHandler(error);
      this.showSnackbar("error-snackbar", "ERROR", "error", data);
    })
  }

  cancel() {
    this.dialogRef.close();
  }

  addTax() {
    if (this.taxForm.invalid) {
      return;
    }
    this.dialogRef.close([this.data?.type, this.taxForm.getRawValue(), this.data?.index]);
  }

  selectedType(item) {
    this.taxForm.get('taxTypeDescription').setValue(item.description)
    this.taxForm.get('taxTypeCode').setValue(item.pricingModelTaxTypeCode)
  }
 
  showSnackbar(style, title, type, description) {
    this._snackBar.openFromComponent(NotificationComponent, {
      panelClass: [style],
      verticalPosition: "top",
      horizontalPosition: "end",
      duration: 3000,
      data: {
        title: title,
        description: description,
        icon: `xlog-${type}`,
      },
    });
  }
}
