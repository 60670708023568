import { Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { fuseAnimations } from "@fuse/animations";
import { FuseSplashScreenService } from "@fuse/services/splash-screen.service";
import { FuseUtils } from "@fuse/utils";

@Component({
  selector: "app-audit-logs-view",
  templateUrl: "./audit-logs-view.component.html",
  styleUrls: ["./audit-logs-view.component.scss"],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class AuditLogsViewComponent implements OnInit {
  data: any;
  detailsValue: any;
  detailsKey: any;
  constructor(
    public dialogRef: MatDialogRef<AuditLogsViewComponent>,
    public dialog: MatDialog,
    private _fuseSplashScreenService: FuseSplashScreenService,
    @Inject(MAT_DIALOG_DATA) private _data: any
  ) {
    this.data = _data;
  }

  ngOnInit(): void {
    this._fuseSplashScreenService.show();
    if (this.data.value.value !== "null") {
      let a = JSON.parse(this.data.value.value);
      if (a.UserProfileAuditLogModel) {
        a["UserProfileAuditLogModel"] = [a["UserProfileAuditLogModel"]];
      }
      this.detailsValue = [a];
      this.detailsKey = Object.keys(JSON.parse(this.data.value.value));
      if (this.detailsKey) {
        this._fuseSplashScreenService.hide();
      }
    } else {
      this._fuseSplashScreenService.hide();
    }
  }

  plainConvertDateToLocal(date) {
    return FuseUtils.plainConvertDateToLocal(date, "MMM DD, YYYY hh:mm:ss a");
  }

  checkArray(item) {
    switch (item) {
      case "ProfilePermission":
      case "UserProfile":
      case "ChargeTypeAmountBased":
      case "ChargeTypeStandardFixed":
      case "ChargeTypeStandardPercentage":
      case "ChargeTypeVolumeBased":
      case "CountryCurrencies":
      case "CompanyServices":
      case "UserProfileAuditLogModel":
        return true;
      default:
        return false;
    }
  }
}
