<h1 mat-dialog-title class="text-uppercase">{{ data?.title }}</h1>
<div mat-dialog-content>
  <div fxLayout="row">
    <form fxLayout="column" fxFlex [formGroup]="taxForm">
      <div fxLayout="row" fxLayoutGap="10px">
        <div fxLayout="column" fxFlex>
          <mat-form-field floatLabel="always" fxFlex class="px-8">
            <mat-label>Tax Type</mat-label>
            <mat-select
              formControlName="taxTypeCode"
              [placeholder]="taxTypes.length ? '' : 'No tax type available'"
            >
              <mat-option
                *ngFor="let item of taxTypes"
                [value]="item.pricingModelTaxTypeCode"
                (click)="selectedType(item)"
                [disabled]="data?.type === 'Edit' ? true : false"
                >{{ item.description }}</mat-option
              >
              <!-- <mat-option value="NAT">NAT</mat-option> -->
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div fxLayout="row" fxLayoutGap="10px">
        <div fxLayout="column" fxFlex>
          <mat-form-field floatLabel="always" fxFlex class="px-8">
            <mat-label>Tax Percent</mat-label>
            <input
              matInput
              input="text"
              currencyMask
              maxlength="5"
              max="100"
              autocapitalize="off"
              autocomplete="off"
              autocorrect="off"
              spellcheck="off"
              max="100"
              required
              formControlName="taxPercent"
            />
            <mat-icon matSuffix>%</mat-icon>
          </mat-form-field>
          <div class="px-8">
            <mat-error *ngIf="taxForm.get('taxPercent').hasError('max')">
              Maximum tax percent is 100.
            </mat-error>
            <mat-error *ngIf="taxForm.get('taxPercent').hasError('min')">
              Minimum tax percent is 1.
            </mat-error>
          </div>
        </div>
      </div>
      <div fxLayout="row" fxLayoutGap="10px" *ngIf="data?.taxExemption">
        <div fxLayout="column" fxFlex>
          <mat-form-field floatLabel="always" fxFlex class="px-8">
            <mat-label>Tax Exemption</mat-label>
            <mat-select formControlName="taxExemption">
              <mat-option value="No">No</mat-option>
              <mat-option value="Yes">Yes</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>
</div>
<div mat-dialog-actions>
  <div fxLayout="row" fxLayoutAlign="end end" fxFlex fxLayoutGap="10px">
    <button mat-button (click)="cancel()" class="fuse-navy-900">Cancel</button>
    <button
      mat-button
      class="accent-500"
      (click)="addTax()"
      [disabled]="taxForm.invalid ? true : false"
    >
      {{ data?.type === "Add" ? "Add" : "Save" }}
    </button>
  </div>
</div>
