import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class SharedService {
  private chargeTypeTieringMode: BehaviorSubject<boolean>;
  private pricingModule: BehaviorSubject<boolean>;
  private chargeTieringMode: BehaviorSubject<boolean>;
  private chargeTypeTieringFormValue: BehaviorSubject<boolean>;
  private editChargeTypeTieringFormValue: BehaviorSubject<boolean>;
  private countrySettingsFormValue: BehaviorSubject<any>;
  private dataSourceVolumeAmountType: BehaviorSubject<any>;
  private dataSourceStandardPercentageFixed: BehaviorSubject<any>;
  private chargeTypeLookup: BehaviorSubject<any>;
  private unitOfMeasurementLookup: BehaviorSubject<any>;
  private triggerSaveUser: BehaviorSubject<any>;
  private countrySettingsPricingFormValue: BehaviorSubject<any>;

  private isChatSupport: BehaviorSubject<any | null> = new BehaviorSubject(
    null
  );
  private isLogin: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _commodityDetails: BehaviorSubject<any | null> = new BehaviorSubject(
    null
  );

  private refreshing: BehaviorSubject<boolean>;
  private cyOperatorDetails: BehaviorSubject<any>;

  constructor() {
    this.chargeTypeTieringMode = new BehaviorSubject<boolean>(false);
    this.pricingModule = new BehaviorSubject<boolean>(false);
    this.chargeTieringMode = new BehaviorSubject<boolean>(false);
    this.chargeTypeTieringFormValue = new BehaviorSubject<boolean>(false);
    this.editChargeTypeTieringFormValue = new BehaviorSubject<boolean>(false);
    this.countrySettingsFormValue = new BehaviorSubject<any>(null);
    this.dataSourceVolumeAmountType = new BehaviorSubject<any>(null);
    this.dataSourceStandardPercentageFixed = new BehaviorSubject<any>(null);
    this.chargeTypeLookup = new BehaviorSubject<any>(null);
    this.unitOfMeasurementLookup = new BehaviorSubject<any>(null);
    this.triggerSaveUser = new BehaviorSubject<any>(null);
    this.countrySettingsPricingFormValue = new BehaviorSubject<any>(null);
    this.refreshing = new BehaviorSubject<boolean>(false);
    this.cyOperatorDetails = new BehaviorSubject<any>(null);
  }

  getUnitOfMeasureMentLookup(): Observable<boolean> {
    return this.unitOfMeasurementLookup.asObservable();
  }

  setUnitOfMeasureMentLookup(value): void {
    this.unitOfMeasurementLookup.next(value);
  }

  getChargeTypeLookup(): Observable<boolean> {
    return this.chargeTypeLookup.asObservable();
  }

  setChargeTypeLookup(value): void {
    this.chargeTypeLookup.next(value);
  }

  getVolumeAmountDataSource(): Observable<boolean> {
    return this.dataSourceVolumeAmountType.asObservable();
  }

  setVolumeAmountDataSource(value): void {
    this.dataSourceVolumeAmountType.next(value);
  }

  getStandardPercentageFixed(): Observable<boolean> {
    return this.dataSourceStandardPercentageFixed.asObservable();
  }

  setStandardPercentageStandardFixed(value): void {
    this.dataSourceStandardPercentageFixed.next(value);
  }

  getChargeTieringMode(): Observable<boolean> {
    return this.chargeTypeTieringMode.asObservable();
  }

  setChargeTieringMode(value): void {
    this.chargeTypeTieringMode.next(value);
  }

  getPricingModule(): Observable<boolean> {
    return this.pricingModule.asObservable();
  }

  setPricingModule(value): void {
    this.pricingModule.next(value);
  }

  getChargeTiering(): Observable<boolean> {
    return this.chargeTieringMode.asObservable();
  }

  setChargeTiering(value): void {
    this.chargeTieringMode.next(value);
  }

  getChargeTypeTieringFormValue(): Observable<boolean> {
    return this.chargeTypeTieringFormValue.asObservable();
  }

  setChargeTypeTieringFormValue(value): void {
    this.chargeTypeTieringFormValue.next(value);
  }

  getEditChargeTypeTieringFormValue(): Observable<boolean> {
    return this.editChargeTypeTieringFormValue.asObservable();
  }

  setEditChargeTypeTieringFormValue(value): void {
    this.editChargeTypeTieringFormValue.next(value);
  }

  setCountrySettingsFormValue(value): void {
    this.countrySettingsFormValue.next(value);
  }

  getCountrySettingsFormValue(): Observable<boolean> {
    return this.countrySettingsFormValue.asObservable();
  }

  setCountrySettingsPricingFormValue(value): void {
    this.countrySettingsPricingFormValue.next(value);
  }

  getCountrySettingsPricingFormValue(): Observable<boolean> {
    return this.countrySettingsPricingFormValue.asObservable();
  }

  getTriggerSaveUser(): Observable<boolean> {
    return this.triggerSaveUser.asObservable();
  }

  setTriggerSaveUser(value): void {
    this.triggerSaveUser.next(value);
  }

  get chatSupport(): Observable<any> {
    return this.isChatSupport.asObservable();
  }

  setChatSupport(value) {
    this.isChatSupport.next(value);
  }
  get getLogin(): Observable<any> {
    return this.isLogin.asObservable();
  }

  setLogin(value) {
    this.isLogin.next(value);
  }

  get getCommodity(): Observable<any> {
    return this._commodityDetails.asObservable();
  }

  setCommodity(value) {
    this._commodityDetails.next(value);
  }

  setRefresh(value): void {
    this.refreshing.next(value);
  }

  get refresh(): Observable<boolean> {
    return this.refreshing.asObservable();
  }
  setCyOperator(value): void {
    this.cyOperatorDetails.next(value);
  }

  get cyOperator(): Observable<boolean> {
    return this.cyOperatorDetails.asObservable();
  }
}
