import { Action } from '@ngrx/store';
import { CompanySettingsModel } from 'app/core/models/company-settings.model';

export enum CompanySettingsActionTypes {
  SetCompanySettings = '[CompanySettingsModel] Set CompanySettingsModel',
  RemoveCompanySettings = '[CompanySettingsModel] Remove CompanySettingsModel',
}

export class SetCompanySettings implements Action {
  readonly type = CompanySettingsActionTypes.SetCompanySettings;
  constructor(public payload: CompanySettingsModel) {}
}

export class RemoveCompanySettings implements Action {
  readonly type = CompanySettingsActionTypes.RemoveCompanySettings;
  constructor() {}
}

export type CompanySettingsActions = SetCompanySettings | RemoveCompanySettings;
