import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { Subject } from "rxjs";
import { buffer, filter, map, pluck, startWith, takeUntil } from "rxjs/operators";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash";

import { FuseConfigService } from "@fuse/services/config.service";
import { FuseSidebarService } from "@fuse/components/sidebar/sidebar.service";

import { navigation } from "app/navigation/navigation";
import {
  ActivatedRoute,
  ActivationEnd,
  NavigationEnd,
  NavigationExtras,
  PRIMARY_OUTLET,
  Router,
} from "@angular/router";
import { select, Store } from "@ngrx/store";
import { RootState, selectToken, selectUserInformation } from "app/core/store";
import { AuthService } from "app/services/auth/auth.service";
import { RemoveToken } from "app/core/store/token/token.action";
import { RemoveCompanySettings } from "app/core/store/company-settings/company-settings.action";
import { RemoveUserInformation } from "app/core/store/user-info/user-info.action";
import { RemovechatSupport } from "app/core/store/chat-support/chat-support.action";

@Component({
  selector: "toolbar",
  templateUrl: "./toolbar.component.html",
  styleUrls: ["./toolbar.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ToolbarComponent implements OnInit, OnDestroy {
  horizontalNavbar: boolean;
  rightNavbar: boolean;
  hiddenNavbar: boolean;
  languages: any;
  navigation: any;
  selectedLanguage: any;
  userStatusOptions: any[];
  breadcrumbList: Array<any> = [];

  isNavigationEnd = (ev: Event) => ev instanceof NavigationEnd;
  isActivationEnd = (ev: Event) => ev instanceof ActivationEnd;

  // Private
  private _unsubscribeAll: Subject<any>;
  masterBreadcrumbList: any;
  _route: string;
  displayBreadcrumbList: any[];
  initialUrl: any;
  route: string;
  user: any;
  showBreadcrumbs: boolean = false
  currentRoute: string;

  /**
   * Constructor
   *
   * @param {FuseConfigService} _fuseConfigService
   * @param {FuseSidebarService} _fuseSidebarService
   * @param {TranslateService} _translateService
   */
  constructor(
    private _fuseConfigService: FuseConfigService,
    private _fuseSidebarService: FuseSidebarService,
    private _translateService: TranslateService,
    private _router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store<RootState>,
    private authService: AuthService

  ) {
    // Set the defaults
    this.userStatusOptions = [
      {
        title: "Online",
        icon: "icon-checkbox-marked-circle",
        color: "#4CAF50",
      },
      {
        title: "Away",
        icon: "icon-clock",
        color: "#FFC107",
      },
      {
        title: "Do not Disturb",
        icon: "icon-minus-circle",
        color: "#F44336",
      },
      {
        title: "Invisible",
        icon: "icon-checkbox-blank-circle-outline",
        color: "#BDBDBD",
      },
      {
        title: "Offline",
        icon: "icon-checkbox-blank-circle-outline",
        color: "#616161",
      },
    ];

    this.languages = [
      {
        id: "en",
        title: "English",
        flag: "us",
      },
      {
        id: "tr",
        title: "Turkish",
        flag: "tr",
      },
    ];

    this.navigation = navigation;

    // Set the private defaults
    this._unsubscribeAll = new Subject();


  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to the config changes
    this._fuseConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((settings) => {
        this.horizontalNavbar = settings.layout.navbar.position === "top";
        this.rightNavbar = settings.layout.navbar.position === "right";
        this.hiddenNavbar = settings.layout.navbar.hidden === true;
      });

    // Set the selected language from default languages
    this.selectedLanguage = _.find(this.languages, {
      id: this._translateService.currentLang,
    });
    this._router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        startWith(this._router)
      )
      .subscribe((event: NavigationEnd) => {
        this.currentRoute = event.url;
        if (this.currentRoute.includes('coming-soon')) {
          this.showBreadcrumbs = false
          return
        }
        if (this.currentRoute.includes('no-permission-error')) {
          this.showBreadcrumbs = false
          return
        }
        // this.listenRouting();
        this.showBreadcrumbs = true
      })

    this.store
      .pipe(select(selectUserInformation))
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((user) => {
        this.user = user
      })


  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebarOpen(key): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }

  /**
   * Search
   *
   * @param value
   */
  search(value): void {
    // Do your search here...
  }

  /**
   * Set the language
   *
   * @param lang
   */
  setLanguage(lang): void {
    // Set the selected language for the toolbar
    this.selectedLanguage = lang;

    // Use the selected language for translations
    this._translateService.use(lang.id);
  }
  /**
   * Toggle sidebar folded status
   */
  toggleSidebarFolded(): void {
    this._fuseSidebarService.getSidebar("navbar").toggleFold();
  }

  // listenRouting() {
  //   this._router.events
  //     .pipe(filter((event) => event instanceof NavigationEnd))
  //     .pipe(map(() => this.activatedRoute))
  //     .pipe(
  //       map((route) => {
  //         while (route.firstChild) {
  //           route = route.firstChild;
  //         }
  //         return route;
  //       })
  //     )
  //     .subscribe((route) => {
  //       this.breadcrumbList = [];
  //       let snapshot = this._router.routerState.snapshot;
  //       let url = snapshot.url;
  //       let routeData = route.snapshot.routeConfig;
  //       let params = snapshot.root.params;
  //       let labels = routeData?.data?.breadcrumb
  //         ? routeData?.data?.breadcrumb.split("/")
  //         : routeData?.path.split("/");
  //       let routes = routeData?.data?.breadcrumb
  //         ? routeData?.data?.breadcrumb.split("/")
  //         : routeData?.path.split("/");
  //       let queryParams = snapshot.root.queryParams;

  //       if (route.snapshot.queryParamMap.keys.length > 0) {
  //         queryParams = {};
  //         for (let key of route.snapshot.queryParamMap.keys) {
  //           queryParams[key] = route.snapshot.queryParamMap.get(key);
  //         }
  //       }
  //       let intialRoute = routeData?.data?.title
  //       if(route?.queryParams["value"]['name']){
  //         if(routeData?.data?.title?.toLowerCase().includes('manage accounts')){
  //          let arr = []
  //          arr = intialRoute.split("/")
  //           arr.splice(2,0,route?.queryParams["value"]['name'])
  //           intialRoute = arr.join('/')
  //         }
  //       }

  //       this.breadcrumbList.push({
  //         url: url,
  //         label: {
  //           labels: routeData?.data?.breadcrumb
  //             ? routeData?.data?.breadcrumb.split("/")
  //             : routeData?.path.split("/"),
  //           routes: intialRoute
  //             ? intialRoute.split("/")
  //             : routeData?.path.split("/"),
  //         },
  //         queryParams: queryParams,
  //         params: params,
  //         isBackButton: labels.length > 1,
  //       });
  //     });

  // }

  // breadClick(name, index) {
  //     if(name === "name"){
  //       return false;
  //     }
  //     let navigationExtras: NavigationExtras;
  //     const newRoute = this.breadcrumbList[0].url.split(name).shift() + name;
  //     if (this.breadcrumbList[0].queryParams) {
  //       navigationExtras = {
  //         queryParams: this.breadcrumbList[0].queryParams,
  //       };
  //     }
  //     if (navigationExtras && index !== 1 && index !== 0) {
  //       this._router.navigate([newRoute], navigationExtras);
  //     } else {
  //       this._router.navigate([newRoute], {});
  //     }
  // }

  logout() {
    this.store
      .pipe(select(selectToken))
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((token) => {
        if (token) {
          const { accessToken } = token
          this.authService.logout(accessToken).subscribe((res) => {
            if (res.success) {
              this.store.dispatch(new RemoveToken())
              this.store.dispatch(new RemoveCompanySettings())
              this.store.dispatch(new RemovechatSupport())
              this.store.dispatch(new RemoveUserInformation())
              window.location.reload()
              setTimeout(() => {
                this._router.navigate(['/auth/login'], {});
              }, 1000);
            }
          })
        }
      });
  }
}
