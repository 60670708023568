import { Action } from "@ngrx/store";
import { IPorts } from "app/core/models/ports.model";

export enum PortActionTypes {
  SetPort = "[PortModel] Set PortModel",
  RemovePort = "[PortModel] Remove PortModel",
}

export class SetPort implements Action {
  readonly type = PortActionTypes.SetPort;
  constructor(public payload: IPorts) {}
}

export class RemovePort implements Action {
  readonly type = PortActionTypes.RemovePort;
  constructor() {}
}

export type PortActions = SetPort | RemovePort;
