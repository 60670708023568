<div
  fxLayout="column"
  id="trip-simulation-container"
  class="responsiveTripSimulation"
>
  <div
    mat-button
    [@animate]="{ value: '*', params: { delay: '300ms', x: '100%' } }"
    fxLayout="column"
    fxLayoutAlign="start stretch"
  >
    <div
      class="accent-500 p-24 font-size-18"
      fxLayout="row"
      fxLayoutAlign.lt-sm="start"
    >
      <span
        fxHide.lt-sm
        fxShow
        class="m-auto"
        fxLayout="column"
        fxLayoutAlign="start start"
        fxFlex.gt-md="70%"
        fxFlex.gt-sm="55%"
        fxFlex.gt-xs="35%"
      >
        {{ data.title }}
      </span>
      <span
        fxHide.lt-sm
        fxShow
        fxLayout="column"
        fxLayoutAlign="start start"
        fxFlex.gt-md="25%"
        fxFlex.gt-sm="40%"
        fxFlex.gt-xs="60%"
        class="pl-48"
      >
        <div>Service Request ID</div>
        <div>
          {{ tripDetails?.serviceTicketId || data.serviceId }}
        </div>
      </span>
      <span
        fxHide
        fxShow.lt-sm
        class="m-auto font-size-14 responsiveText"
        fxLayout="column"
        fxLayoutAlign="start start"
        fxFlex.gt-md="70%"
        fxFlex.gt-sm="55%"
        fxFlex.gt-xs="35%"
      >
        Simulate Route
      </span>
      <span
        class="cursor-pointer"
        fxLayout="column"
        fxLayoutAlign="center end"
        (click)="matDialogRef.close(false)"
        aria-label="Close dialog"
        fxFlex="5"
      >
        <mat-icon> cancel </mat-icon>
      </span>
    </div>
  </div>

  <div
    class="tripInfo m-24 responsiveModal"
    fxLayout="column"
    fxLayoutAlign="center center"
    fxFlex.gt-xs="1 0 auto"
  >
    <div class="border map-container">
      <agm-map
        class="mapContainer"
        [fullscreenControl]="true"
        [rotateControl]="false"
        [latitude]="lat"
        [longitude]="lng"
        [zoom]="zoom"
        [scaleControl]="true"
        [streetViewControl]="false"
        [styles]="styles"
      >
        <agm-direction
          [renderOptions]="renderOptions"
          [origin]="originPlanned"
          [destination]="destinationPlanned"
        >
        </agm-direction>

        <span *ngFor="let coordinate of markers; let i = index">
          <span *ngIf="simulated">
            <div fxLayout="column" *ngIf="coordinate?.reportIcon">
              <agm-marker
                [agmFitBounds]="!isPlayed"
                [latitude]="coordinate?.latitude"
                [longitude]="coordinate?.longitude"
                [iconUrl]="coordinate.iconUrl"
                [animation]="coordinate.animation"
                (mouseOver)="infoWindow.open()"
                (mouseOut)="infoWindow.close()"
                *ngIf="coordinate.name === 'Driver App' ? !is : true"
              >
                <agm-info-window #infoWindow>
                  <div class="text-bold text-center">
                    {{ report?.jobOrderNo + " / " + report?.tripNo }}
                  </div>
                  <div class="red-fg text-center p-4">Vehicle Breakdown</div>

                  <div class="text-center border-top p-4">
                    {{
                      report?.driverName +
                        " / " +
                        report?.brand +
                        " / " +
                        report?.plateNumber
                    }}
                  </div>
                </agm-info-window>
              </agm-marker>
            </div>
          </span>
          <div fxLayout="column" *ngIf="!coordinate?.reportIcon">
            <agm-marker
              [agmFitBounds]="!isPlayed"
              [latitude]="coordinate?.latitude"
              [longitude]="coordinate?.longitude"
              [iconUrl]="coordinate.iconUrl"
              [animation]="coordinate.animation"
              (mouseOver)="infoWindow.open()"
              (mouseOut)="infoWindow.close()"
              *ngIf="coordinate.name === 'Driver App' ? false : true"
            >
              <agm-info-window #infoWindow>
                <div *ngIf="coordinate.name === 'Origin'">
                  Origin: {{ coordinate.address }}
                </div>
                <div *ngIf="coordinate.name === 'Destination'">
                  Destination: {{ coordinate.address }}
                </div>
                <div
                  *ngIf="
                    coordinate.name === 'Actual Start' ||
                    coordinate.name === 'Actual Origin' ||
                    coordinate.name === 'Actual Destination' ||
                    coordinate.name === 'Driver App'
                  "
                  style="text-align: center"
                >
                  {{ coordinate.name }}
                </div>
                <div *ngIf="coordinate.StartDateTime !== '-'">
                  Date:
                  {{
                    coordinate.StartDateTime
                      ? plainConvertDateToLocal(coordinate.StartDateTime)
                      : "--"
                  }}
                </div>
                <div>Latitude: {{ coordinate.latitude }}</div>
                <div>Longitude: {{ coordinate.longitude }}</div>
              </agm-info-window>
            </agm-marker>
          </div>
        </span>

        <!-- Driver Trip Simulation -->
        <div *ngIf="isPlayedShow">
          <span *ngIf="sos">
            <span *ngIf="!simulated">
              <agm-marker
                *ngFor="let coordinate of markerPlay; let i = index"
                [latitude]="coordinate?.latitude"
                [longitude]="coordinate?.longitude"
                [iconUrl]="coordinate?.icon"
              >
              </agm-marker>
            </span>
          </span>
          <span *ngIf="!sos">
            <agm-marker
              *ngFor="let coordinate of markerPlay; let i = index"
              [latitude]="coordinate?.latitude"
              [longitude]="coordinate?.longitude"
              [iconUrl]="coordinate?.icon"
            >
            </agm-marker>
          </span>
          <ng-container *ngIf="type === 'container'; else driverLine">
            <agm-marker
              *ngFor="let coordinate of playTrip; let i = index"
              [latitude]="coordinate?.latitude"
              [longitude]="coordinate?.longitude"
              [iconUrl]="containerPoint"
            >
            </agm-marker>
          </ng-container>
          <ng-template #driverLine>
            <agm-polyline
              [visible]="true"
              [strokeWeight]="3"
              [strokeColor]="'#0380A7'"
            >
              <agm-polyline-point
                *ngFor="let coordinate of playTrip; let i = index"
                [latitude]="coordinate?.latitude"
                [longitude]="coordinate?.longitude"
              >
              </agm-polyline-point>
            </agm-polyline>
          </ng-template>
        </div>
      </agm-map>
      <div fxLayout="column" class="map-legend" style="vertical-align: middle">
        <div class="p-8">
          <div class="leg-sim-btn">
            <legends-map *ngIf="!isLegendHidden" height="430px"></legends-map>
            <div fxLayout="column" class="py-8">
              <div
                fxLayout="row"
                fxLayout.sm="column"
                class="legend"
                fxLayoutAlign="start"
                fxLayoutGap="5px"
              >
                <button
                  mat-raised-button
                  (click)="isLegendHidden = !isLegendHidden"
                >
                  <div fxLayout="row" class="px-12">
                    <p>Legends</p>
                    <div class="pending">
                      <img src="assets/icons/legend/Pending.png" alt="" />
                    </div>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="px-24 pt-24 responsiveContNum"
      fxLayout="column"
      fxLayoutAlign="center end"
    >
      <ng-container *ngIf="type == 'container'; else driver">
        <span class="font-weight-600"> Container Number </span>
        <span class="font-weight-900">
          {{ selectedTripDetails?.containerNumber || "--" }} (IMEI:
          {{ selectedTripDetails?.trackingDeviceId || "--" }})
        </span>
      </ng-container>
      <ng-template #driver>
        <span class="font-weight-600"> Container Number </span>
        <ng-container *ngIf="containerNumberChecker; else singleContainer">
          <ng-container *ngFor="let item of containerNumberArray">
            <span class="font-weight-900">
              {{ item?.containerNumber || "--" }} (IMEI:
              {{ item?.trackingDeviceId || "--" }})
            </span>
          </ng-container>
        </ng-container>
        <ng-template #singleContainer>
          <span class="font-weight-900">
            {{ item?.containerNumber || "--" }} (IMEI:
            {{ item?.trackingDeviceId || "--" }})
          </span>
        </ng-template>
      </ng-template>
    </div>
    <ng-container *ngIf="selectedOption == 'container'">
      <div
        class="border-bottom tripDits"
        *ngFor="let tripDits of tripDetailsData; let i = index"
      >
        <div fxLayout="column">
          <div
            class="px-24 pt-24 responsiveTripNum"
            fxLayout="column"
            fxLayout.lt-sm="row"
            fxLayoutGap.lt-sm="10px"
            [ngClass]="'pl-0'"
          >
            <span class="font-weight-600"> Trip Number </span>
            <span class="font-weight-900">
              {{ tripDits?.jobOrderNo }} -
              {{ tripDits.tripNo }}
            </span>
          </div>
          <div
            fxShow
            fxHide.lt-sm
            class="my-24"
            fxLayout="row"
            fxLayoutAlign="start start"
            fxFlex="1 0 auto"
          >
            <div fxLayout="column" fxLayoutAlign="start start" fxFlex="20">
              <div fxLayout="row" fxLayoutAlign="center center" fxFlex="100">
                <div
                  class="pr-6 imgLayout"
                  fxLayout="column"
                  fxLayoutAlign="center center"
                  fxFlex="10"
                >
                  <mat-icon svgIcon="xlog-route" class="s-35"></mat-icon>
                </div>
                <div
                  class="fs-12"
                  fxLayout="column"
                  fxLayoutAlign="start start"
                  fxFlex
                >
                  <div fxLayout="row" fxLayoutAlign="start start">Origin</div>
                  <div fxLayout="row" fxLayoutAlign="start start">
                    <strong class="pr-4">
                      {{ tripDits.originAddress }},
                      {{ tripDits.originStateCityName }}
                    </strong>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="px-8 fs-12"
              fxLayout="column"
              fxLayoutAlign="start start"
              fxFlex="20"
            >
              <div fxLayout="row" fxLayoutAlign="start start">
                Start Trip:
                {{
                  tripDits.actualStart
                    ? plainConvertDateToLocal(tripDits.actualStart)
                    : "--"
                }}
              </div>
              <div fxLayout="row" fxLayoutAlign="start start">
                Driver Name: {{ tripDits.driverName }}
              </div>
            </div>
            <div
              class="fs-12"
              fxLayout="column"
              fxLayoutAlign="start start"
              fxFlex="20"
            >
              <div fxLayout="row" fxLayoutAlign="start start">
                Arrivals:
                {{
                  tripDits.actualTimeDeparture
                    ? plainConvertDateToLocal(tripDits.actualTimeDeparture)
                    : "--"
                }}
              </div>
              <div fxLayout="row" fxLayoutAlign="start start">
                Plate Number: {{ tripDits.plateNumber }}
              </div>
            </div>
            <div
              class="px-8 fs-12"
              fxLayout="column"
              fxLayoutAlign="start start"
              fxFlex="20"
            >
              <div fxLayout="row" fxLayoutAlign="start start">
                End Trip:
                {{
                  tripDits.actualTimeArival
                    ? plainConvertDateToLocal(tripDits.actualTimeArival)
                    : "--"
                }}
              </div>
            </div>
            <div fxLayout="column" fxLayoutAlign="start start" fxFlex="20">
              <div fxLayout="row" fxLayoutAlign="center center" fxFlex="100">
                <div
                  class="pr-6"
                  fxLayout="column"
                  fxLayoutAlign="center center"
                  fxFlex="10"
                >
                  <mat-icon svgIcon="xlog-route" class="s-35"></mat-icon>
                </div>
                <div
                  class="fs-12"
                  fxLayout="column"
                  fxLayoutAlign="start start"
                  fxFlex
                >
                  <div fxLayout="row" fxLayoutAlign="start start">
                    Destination
                  </div>
                  <div fxLayout="row" fxLayoutAlign="start start">
                    <strong>
                      {{ tripDits.destinationAddress }},
                      {{ tripDits.destinationStateCityName }}
                    </strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            fxShow.lt-sm
            fxHide
            class="my-24 mr-12 font-size-12"
            fxLayout="column"
            fxLayoutAlign="start start"
            fxLayoutGap="6px"
          >
            <div class="marginBottom">
              Driver Name: {{ tripDits.driverName }}
            </div>
            <div class="marginBottom">
              Plate Number: {{ tripDits.plateNumber }}
            </div>
            <div class="marginBottom">
              Origin:
              <strong>
                {{ tripDits.originAddress }}
              </strong>
            </div>
            <div class="marginBottom" [ngClass]="'mr-6'">
              Destination:
              <strong>
                {{ tripDits.destinationAddress }}
              </strong>
            </div>
            <div class="marginBottom">
              Start Trip: {{ plainConvertDateToLocal(tripDits.actualStart) }}
            </div>
            <div class="marginBottom">
              Arrival:
              {{ plainConvertDateToLocal(tripDits.actualTimeDeparture) }}
            </div>
            <div>
              End Trip: {{ plainConvertDateToLocal(tripDits.actualTimeArival) }}
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="selectedOption == 'driver'">
      <div class="border-bottom tripDits">
        <div fxLayout="column">
          <div
            class="px-24 pt-24"
            fxLayout="column"
            fxLayout.lt-sm="row"
            fxLayoutGap.lt-sm="10px"
            [ngClass.lt-sm]="'pl-0'"
          >
            <span class="font-weight-600"> Trip Number </span>
            <span class="font-weight-900">
              {{ selectedTripDetails?.jobOrderNo }} -
              {{ selectedTripDetails.tripNo }}
            </span>
          </div>
          <div
            fxHide.lt-sm
            class="my-24"
            fxLayout="row"
            fxLayoutAlign="start start"
            fxFlex="1 0 auto"
          >
            <div fxLayout="column" fxLayoutAlign="start start" fxFlex="20">
              <div fxLayout="row" fxLayoutAlign="center center" fxFlex="100">
                <div
                  class="pr-6 imgLayout"
                  fxLayout="column"
                  fxLayoutAlign="center center"
                  fxFlex="10"
                >
                  <mat-icon svgIcon="xlog-route" class="s-35"></mat-icon>
                </div>
                <div
                  class="fs-12"
                  fxLayout="column"
                  fxLayoutAlign="start start"
                  fxFlex
                >
                  <div fxLayout="row" fxLayoutAlign="start start">Origin</div>
                  <div fxLayout="row" fxLayoutAlign="start start">
                    <strong class="pr-4">
                      {{ selectedTripDetails.originAddress }},
                      {{ selectedTripDetails.originStateCityName }}
                    </strong>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="p-4 fs-12"
              fxLayout="column"
              fxLayoutAlign="start start"
              fxFlex="20"
            >
              <div fxLayout="row" fxLayoutAlign="start start">
                Start Trip:
                {{
                  selectedTripDetails.actualStart
                    ? plainConvertDateToLocal(selectedTripDetails.actualStart)
                    : "--"
                }}
              </div>
              <div fxLayout="row" fxLayoutAlign="start start">
                Driver Name: {{ selectedTripDetails.driverName }}
              </div>
            </div>
            <div
              class="fs-12"
              fxLayout="column"
              fxLayoutAlign="start start"
              fxFlex="20"
            >
              <div fxLayout="row" fxLayoutAlign="start start">
                Arrival:
                {{
                  selectedTripDetails.actualTimeDeparture
                    ? plainConvertDateToLocal(
                        selectedTripDetails.actualTimeDeparture
                      )
                    : "--"
                }}
              </div>
              <div fxLayout="row" fxLayoutAlign="start start">
                Plate Number: {{ selectedTripDetails.plateNumber }}
              </div>
            </div>
            <div
              class="p-4 fs-12"
              fxLayout="column"
              fxLayoutAlign="start start"
              fxFlex="20"
            >
              <div fxLayout="row" fxLayoutAlign="start start">
                End Trip:
                {{
                  selectedTripDetails.actualTimeArival
                    ? plainConvertDateToLocal(
                        selectedTripDetails.actualTimeArival
                      )
                    : "--"
                }}
              </div>
            </div>
            <div fxLayout="column" fxLayoutAlign="start start" fxFlex="20">
              <div fxLayout="row" fxLayoutAlign="center center" fxFlex="100">
                <div
                  class="pr-6"
                  fxLayout="column"
                  fxLayoutAlign="center center"
                  fxFlex="10"
                >
                  <mat-icon svgIcon="xlog-route" class="s-35"></mat-icon>
                </div>
                <div
                  class="fs-12"
                  fxLayout="column"
                  fxLayoutAlign="start start"
                  fxFlex
                >
                  <div fxLayout="row" fxLayoutAlign="start start">
                    Destination
                  </div>
                  <div fxLayout="row" fxLayoutAlign="start start">
                    <strong>
                      {{ selectedTripDetails.destinationAddress }},
                      {{ selectedTripDetails.destinationStateCityName }}
                    </strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- showOnMobile -->
          <div
            fxHide
            fxShow.lt-sm
            class="my-24 mr-12 fs-12"
            fxLayout="column"
            fxLayoutAlign="start start"
            fxFlex="1 0 auto"
            fxLayoutGap="6px"
          >
            <div fxLayout="row" fxLayoutAlign="start start">
              Driver Name: {{ selectedTripDetails.driverName }}
            </div>
            <div fxLayout="row" fxLayoutAlign="start start">
              Plate Number: {{ selectedTripDetails.plateNumber }}
            </div>
            <div class="marginBottom">
              Origin:
              <strong>
                {{ selectedTripDetails.originAddress }}
              </strong>
            </div>
            <div class="marginBottom" [ngClass]="'mr-6'">
              Destination:
              <strong>
                {{ selectedTripDetails.destinationAddress }}
              </strong>
            </div>
            <div fxLayout="row" fxLayoutAlign="start start">
              Start Trip:
              {{ plainConvertDateToLocal(selectedTripDetails.actualStart) }}
            </div>
            <div fxLayout="row" fxLayoutAlign="start start">
              Arrival:
              {{
                plainConvertDateToLocal(selectedTripDetails.actualTimeDeparture)
              }}
            </div>
            <div fxLayout="row" fxLayoutAlign="start start">
              End Trip:
              {{
                plainConvertDateToLocal(selectedTripDetails.actualTimeArival)
              }}
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <div id="accordion" class="pb-28 responsiveAccord">
    <!-- Accordion for Container -->
    <div
      fxLayout="row"
      fxLayout.lt-sm="column"
      fxLayoutGap.lt-sm="10px"
      [ngClass.lt-sm]="'mt-32'"
    >
      <div fxLayoutAlign.gt-xs="end end" fxFlex="88" class="relative">
        <mat-accordion
          class="example-headers-align"
          *ngIf="selectedOption == 'container'"
          multi
        >
          <mat-expansion-panel class="track-panel" [expanded]="isExpanded">
            <mat-expansion-panel-header
              (click)="isExpanded = !isExpanded"
              [ngClass.lt-sm]="'scale-90 overflow-x-hidden'"
            >
              <mat-panel-title>{{ selectedContainer }}</mat-panel-title>
            </mat-expansion-panel-header>

            <ng-container *ngIf="!tripLevel">
              <div
                *ngFor="let tripDits of tripDetailsData; let i = index"
                class="panel-description"
              >
                <mat-panel-description>
                  <mat-radio-group class="radio-group" name="selectedRoute">
                    <mat-radio-button
                      [value]="tripDits"
                      [checked]="
                        getGroup(tripDits.sequenceNo) === selectedContainerRoute
                      "
                      (click)="handleSelection(tripDits, i)"
                      [ngClass.lt-sm]="'scale-90 overflow-x-hidden'"
                    >
                      {{ tripDits.containerNumber || "--" }} (IMEI:
                      {{ tripDits.trackingDeviceId || "--" }})
                    </mat-radio-button>
                  </mat-radio-group>
                  <br />
                </mat-panel-description>
              </div>
            </ng-container>
            <ng-container *ngIf="tripLevel">
              <div
                *ngFor="
                  let tripDits of data?.tripDetails?.containersInventory;
                  let i = index
                "
                class="panel-description"
              >
                <mat-panel-description>
                  <mat-radio-group class="radio-group" name="selectedRoute">
                    <mat-radio-button
                      [value]="tripDits"
                      [checked]="
                        getGroup(tripDits.sequenceNo) === selectedContainerRoute
                      "
                      (click)="handleSelection(tripDits, i)"
                      [ngClass.lt-sm]="'scale-90 overflow-x-hidden'"
                    >
                      {{ tripDits.containerNumber || "--" }} (IMEI:
                      {{ tripDits.trackingDeviceId || "--" }})
                    </mat-radio-button>
                  </mat-radio-group>
                  <br />
                </mat-panel-description>
              </div>
            </ng-container>
            <div
              *ngIf="tripDetails.length == 0"
              fxLayoutAlign="center center"
              class="p-12"
            >
              No Result
            </div>
          </mat-expansion-panel>
        </mat-accordion>

        <!-- Accordion for Driver -->
        <mat-accordion
          class="example-headers-align"
          *ngIf="selectedOption == 'driver'"
          multi
        >
          <mat-expansion-panel class="track-panel" [expanded]="isExpanded">
            <mat-expansion-panel-header
              class="border-bottom"
              (click)="isExpanded = !isExpanded"
              [ngClass.lt-sm]="'scale-90 overflow-x-hidden'"
            >
              <mat-panel-title>{{ selectedRoute }}</mat-panel-title>
            </mat-expansion-panel-header>
            <div
              *ngFor="let tripDits of tripDetailsData; let i = index"
              class="panel-description"
            >
              <mat-panel-description>
                <mat-radio-group class="radio-group" name="selectedRoute">
                  <mat-radio-button
                    [value]="tripDits"
                    [checked]="getGroup(i) === 'A'"
                    (click)="handleSelection(tripDits, i)"
                    [ngClass.lt-sm]="'scale-90 overflow-x-hidden'"
                  >
                    ROUTE {{ getGroup(tripDits.sequenceNo) }}:
                    {{ tripDits?.jobOrderNo }} /
                    {{ tripDits?.tripNo }}
                  </mat-radio-button>
                </mat-radio-group>
                <br />
              </mat-panel-description>
            </div>
            <div
              *ngIf="tripDetails.length == 0"
              fxLayoutAlign="center center"
              class="p-12"
            >
              No Result
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>

      <div
        fxLayoutAlign="end end"
        fxLayoutAlign.lt-sm="center center"
        fxFlex="12"
        class="mr-28 ml-12"
        [ngClass.lt-sm]="'playBtn'"
      >
        <button
          mat-button
          class="accent-500 mx-4 w-90-p br-25"
          (click)="showPlaySnapToRoad(isPlayed ? 'pause' : 'play')"
        >
          <mat-icon style="vertical-align: middle">
            {{ isPlayed ? "pause_circle_filled" : "play_circle_filled" }}
          </mat-icon>
          {{ isPlayed ? "Pause" : " Play" }}
        </button>
      </div>
    </div>
  </div>
</div>
