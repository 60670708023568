import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { fuseAnimations } from '@fuse/animations';

@Component({
  selector: 'app-warning',
  templateUrl: './warning.component.html',
  styleUrls: ['./warning.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations
})
export class WarningComponent implements OnInit {
  data: any;
  constructor(
    public dialogRef: MatDialogRef<WarningComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any
  ) {
    this.data = _data;
  }

  ngOnInit(): void {
  }

  close(){
    this.dialogRef.close();
  }
  
  action(data){
    this.dialogRef.close(data)
  }
}
