import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
  HostListener,
} from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { fuseAnimations } from "@fuse/animations";
import * as moment from "moment";

@Component({
  selector: "app-global-advance-search",
  templateUrl: "./global-advance-search.component.html",
  styleUrls: ["./global-advance-search.component.scss"],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class GlobalAdvanceSearchComponent implements OnInit {
  @Input() columns;
  @Input() options;
  @Input() selectField;
  @Input() dateField;
  @Input() disableField?: any;
  @Output() handleSearch: EventEmitter<any> = new EventEmitter();

  moment = moment;
  form: FormGroup;
  selectRange = {};
  isSingle: boolean;
  innerWidth: any;
  screenSize: string;

  constructor() {}

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    this.checkWidth();
  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.checkWidth();
    this.form = new FormGroup({});
    this.columns.forEach((element) => {
      this.form.addControl(element.replace(/\s+/g, ""), new FormControl(""));
    });
  }

  checkWidth() {
    if (this.innerWidth < 881 && this.innerWidth > 599) {
      this.screenSize = "xsm";
    } else if (this.innerWidth < 1006 && this.innerWidth > 880) {
      this.screenSize = "sm";
    } else if (this.innerWidth < 1444 && this.innerWidth > 1005) {
      this.screenSize = "md";
    } else if (this.innerWidth < 1600 && this.innerWidth > 1443) {
      this.screenSize = "lg";
    } else {
      this.screenSize = "xl";
    }
  }

  checkIfSelectField(value) {
    if (this.selectField) {
      return this.selectField.some((res) => res === value);
    } else {
      return false;
    }
  }

  checkIfDate(value) {
    if (this.dateField) {
      if (this.dateField.some((res) => res === value)) {
        this.isSingle = false;
        return this.dateField.some((res) => res === value);
      } else if (this.dateField.some((res) => res.value === value)) {
        this.isSingle = true;
        return this.dateField.some((res) => res.value === value);
      }
    } else {
      return false;
    }
  }

  getOptions(value) {
    if (this.options) {
      const opt = this.options[value.toLowerCase().replace(/\s+/g, "")];
      return opt ? opt : [];
    } else {
      return [];
    }
  }

  search() {
    this.handleSearch.emit(this.form.getRawValue());
  }

  getCol(value) {
    switch (value) {
      case "PSG 10digit Code":
        return "PSG 10-digit Code";
      case "Origin_Destination":
        return "Origin/Destination";
      case "JobOrder_TripNo":
        return "JobOrder/TripNo";
      case "Booking Party Country":
        return "Country";
      default:
        return value;
    }
  }

  checkDisabled(col) {
    if (this.disableField) {
      let data = this.disableField.filter((res) => res.id === col);
      if (data) {
        data.map((res) => {
          this.form.controls[res.id].disable();
          this.form.controls[res.id].setValue(res.value);
        });
      }
    }
    return false;
  }

  getDisableValue(col) {
    return this.disableField.filter((value) => value.module == col);
  }

  getFormControl(col) {
    return col.replace(/\s+/g, "");
  }

  reset() {
    this.form.reset();
    this.handleSearch.emit(this.form.getRawValue());
  }

  resetDate(form) {
    this.form.get(form.replace(/\s+/g, "")).reset();
  }
}
