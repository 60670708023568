import { Component, Inject, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-e-credit-modal",
  templateUrl: "./e-credit-modal.component.html",
  styleUrls: ["./e-credit-modal.component.scss"],
})
export class ECreditModalComponent implements OnInit {
  walletForm: FormGroup;
  data: any;
  taxTypes: any = [];
  constructor(
    public dialogRef: MatDialogRef<ECreditModalComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    // private countryService: CountrySettingsService,
    private _formBuilder: FormBuilder // private _snackBar: MatSnackBar
  ) {
    this.data = _data;
    console.log(this.data);
  }

  ngOnInit(): void {
    this.walletForm = new FormGroup({
      currency: new FormControl({ value: this.data?.currency, disabled: true }),
      creditLimit: new FormControl("", [
        Validators.required,
        Validators.min(1),
        Validators.pattern(/^\d+\.?\d{0,2}$/),
        Validators.maxLength(12),
      ]),
      country: new FormControl({
        value: this.data?.address?.countryName,
        disabled: true,
      }),
    });
  }

  cancel() {
    this.dialogRef.close();
  }

  addCredit() {
    console.log(this.walletForm);
    if (this.walletForm.invalid) {
      return;
    }
    this.dialogRef.close([this.walletForm.getRawValue()]);
  }
}
