import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { FuseUtils } from '@fuse/utils';
import { select, Store } from '@ngrx/store';
import { NotificationComponent } from 'app/core/component/notification/notification.component';
import { RootState, selectToken } from 'app/core/store';
import { AuthService } from 'app/services/auth/auth.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-profile-change-password',
  templateUrl: './profile-change-password.component.html',
  styleUrls: ['./profile-change-password.component.scss']
})
export class ProfileChangePasswordComponent implements OnInit, OnDestroy {
  resetPasswordForm: any;
  // Private
  private _unsubscribeAll: Subject<any>;
  config: any;
  Currenthide = true;  
  hide = true;
  confirmHide = true;
  hideConfirm = true;
  passwordStrength: boolean = false;
  showPasswordValid: boolean = false;

  constructor(
    private _fuseConfigService: FuseConfigService,
    private _formBuilder: FormBuilder,
    private _fuseSidebarService: FuseSidebarService,
    private authService : AuthService,
    private _snackBar: MatSnackBar,
    private store: Store<RootState>,
    private _fuseSplashScreenService: FuseSplashScreenService,
    ) { 
      // Configure the layout
      this._fuseConfigService.config = {
        layout: {
            navbar   : {
                hidden: true
            },
            toolbar  : {
                hidden: true
            },
            footer   : {
                hidden: true
            },
            sidepanel: {
                hidden: true
            }
        },
        customScrollbars: false
    };
    
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

  ngOnInit(): void {
    this.resetPasswordForm = this._formBuilder.group({
      currentPassword:
      [
        '',
          [
          Validators.required,
          // Validators.minLength(8),
          ]
      ],
      password:
      [
        '',
          [
          Validators.required,
          Validators.minLength(8),
          ]
      ],
      passwordConfirm:
      [
        '',
          [
          Validators.required,
          Validators.minLength(8),
          confirmPasswordValidator
          ]
      ],
  });

  // Update the validity of the 'passwordConfirm' field
  // when the 'password' field changes
  this.resetPasswordForm.get('password').valueChanges
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        this.showPassword(res)
          this.resetPasswordForm.get('passwordConfirm').updateValueAndValidity();
      });
  }

  ngOnDestroy(): void
  {
      // Unsubscribe from all subscriptions
      this._unsubscribeAll.next();
      this._unsubscribeAll.complete();
      this.resetPasswordForm.reset();
  }

  onStrengthChanged(strength: number) {

    switch ( strength ) {
        case 100: 

        this.passwordStrength = true;

        break;

        default: 
        this.passwordStrength = false;

    }


    }

  submit(){
    this._fuseSplashScreenService.show();
    let headerToken;
    let bearer;
    this.store
    .pipe(select(selectToken))
    .pipe(takeUntil(this._unsubscribeAll))
    .subscribe((token) => {
      if(token){
        const { accessToken, idToken } = token;
        headerToken = accessToken;
        bearer = idToken;
      }})
    const form = this.resetPasswordForm.getRawValue()
    const payload = {
      oldPassword: form?.currentPassword,
      password: form?.password,
      passwordConfirmation: form?.passwordConfirm,
    }
    this.authService.profileChangePassword(payload, headerToken, bearer).subscribe((res) => {
      if (res){
        this._fuseSplashScreenService.hide();
          const { message } = res;
          this.showSnackbar(
            "success-snackbar",
            "SUCCESS !",
            "success",
            message
          );
        this._fuseSidebarService.getSidebar('changePassword').toggleOpen();
        this.resetPasswordForm.reset()
      }
    },
    (error) => {
      this._fuseSplashScreenService.hide();
      let data = FuseUtils.errorHandler(error);
      this.showSnackbar("error-snackbar", "ERROR", "error", data);
    }
  )
  }

  showSnackbar(style, title, type, description) {
    this._snackBar.openFromComponent(NotificationComponent, {
      panelClass: [style],
      verticalPosition: "top",
      horizontalPosition: "end",
      duration: 3000,
      data: {
        title: title,
        description: description,
        icon: `xlog-${type}`,
      },
    });
    if(type === 'success'){
      setTimeout(() => {
        location.reload();
      }, 3000);
    }
  }

  showPassword(event){
    if(!this.passwordStrength && event != ''){ 
     return this.showPasswordValid = true;
    }
    if (this.passwordStrength){
    return this.showPasswordValid = true;
    }    
    if (event == ''){
    return this.showPasswordValid = false;
    }
  }

}

export const confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {

  if ( !control.parent || !control )
  {
      return null;
  }

  const password = control.parent.get('password');
  const passwordConfirm = control.parent.get('passwordConfirm');

  if ( !password || !passwordConfirm )
  {
      return null;
  }

  if ( passwordConfirm.value === '' )
  {
      return null;
  }

  if ( password.value === passwordConfirm.value )
  {
      return null;
  }

  return {passwordsNotMatching: true};
};
