import { IPorts } from "app/core/models/ports.model";
import { PortActions, PortActionTypes } from "./port.action";

export const initialState: IPorts = null;
export function reducer(state = initialState, action: PortActions): IPorts {
  switch (action.type) {
    case PortActionTypes.SetPort:
      state = action.payload;
      return state;
    case PortActionTypes.RemovePort:
      state = initialState;
      return state;
    default:
      return state;
  }
}
