<div class="inner-scroll p-24" fxLayout="row" fxLayoutAlign="center center">
  <div
    id="pop-up-wrapper"
    fusePerfectScrollbar
    [@animate]="{ value: '*', params: { delay: '300ms', x: '100%' } }"
  >
    <div id="pop-up-page">
      <div *ngIf="data.data === 'success'" class="s-100" style="color: #47c399">
        <img
          src="/assets/icons/booking-reservation/check-alert.svg"
          class="mb-8"
          alt=""
        />
        <div class="title border-bottom pb-24">
          {{ data.title }}
        </div>
      </div>
      <div *ngIf="data.data === 'error'" class="s-100" style="color: red">
        <img
          src="/assets/icons/booking-reservation/error-alert.svg"
          class="mb-8"
          alt=""
        />
        <div class="title border-bottom pb-24">
          {{ data.title }}
        </div>
      </div>
      <div *ngIf="data.data === 'warning'" class="s-100" style="color: orange">
        <img
          src="/assets/icons/booking-reservation/warning-alert.svg"
          class="mb-8"
          alt=""
        />
        <div class="title border-bottom pb-24">
          {{ data.title }}
        </div>
      </div>

      <div *ngIf="data.data === 'information'" class="s-100 accent-fg">
        <img
          src="/assets/images/svg/Infopopup.svg"
          class="mb-8 information-logo"
          alt=""
        />
        <div class="title border-bottom pb-24">
          {{ data.title }}
        </div>
      </div>

      <div class="description">
        <div [innerHTML]="data.description"></div>
      </div>
      <button
        mat-flat-button
        class="submit-button"
        color="accent"
        *ngIf="
          !data.showButtons &&
          !data.showDelete &&
          !data.showProceed &&
          !data.proceedLater &&
          !data.dashModal
        "
        (click)="matDialogRef.close('close')"
      >
        CLOSE
      </button>
      <div
        class="mt-8"
        fxLayout="row"
        fxLayoutAlign="center space-around"
        *ngIf="data.showButtons"
      >
        <button
          mat-flat-button
          class="grey-600 mr-8"
          (click)="matDialogRef.close()"
        >
          No
        </button>
        <button
          mat-flat-button
          class="submit-button"
          color="accent"
          (click)="save()"
        >
          Yes
        </button>
      </div>
      <div
        class="mt-8"
        fxLayout="row"
        fxLayoutAlign="center space-around"
        *ngIf="data.showDelete"
      >
        <button
          mat-flat-button
          class="grey-600 mr-8"
          (click)="matDialogRef.close([''])"
        >
          No
        </button>
        <button
          mat-flat-button
          class="submit-button"
          color="accent"
          (click)="matDialogRef.close(['delete'])"
        >
          Yes
        </button>
      </div>
      <div
        class="mt-8"
        fxLayout="row"
        fxLayoutAlign="center space-around"
        *ngIf="data.dashModal"
      >
        <button
          mat-flat-button
          class="grey-600 mr-8"
          (click)="matDialogRef.close()"
        >
          Close
        </button>
        <button
          mat-flat-button
          class="submit-button"
          color="accent"
          (click)="save()"
        >
          Proceed
        </button>
      </div>

      <div
        class="mt-8"
        fxLayout="row"
        fxLayoutAlign="center space-around"
        *ngIf="data.proceedLater"
      >
        <button
          mat-flat-button
          class="grey-600 mr-8"
          (click)="matDialogRef.close('close')"
        >
          Later
        </button>
        <button
          mat-flat-button
          class="submit-button"
          color="accent"
          (click)="matDialogRef.close('proceed')"
        >
          Proceed
        </button>
      </div>
    </div>
  </div>
</div>
