<!-- Details container -->

<div class="w-100-p">
  <div class="audit-listing p-8">
    <div fxLayout="column" fxLayoutAlign="space-between">
      <div fxLayout="row" fxLayout.xs="column" class="py-12">
        <div fxFlex="50">
          <h1 class="m-0">
            <strong>{{ title }}</strong>
          </h1>
        </div>
      </div>
      <div class="company-content h-full">
        <div
          fxLayout="row"
          fxLayout.xs="column"
          fxLayoutAlign="space-between center"
          fxLayoutAlign.xs="start"
          fxLayoutGap="12px"
          class="m-24"
          ngClass.xs="m-10"
        >
          <div fxLayout="row" fxLayoutGap="12px">
            <div fxLayout="row" fxLayoutAlign="start center">
              <div>
                <input
                  class="inputSearch border"
                  placeholder="Search "
                  [formControl]="searchFilter"
                  (keyup.enter)="search()"
                />
              </div>
              <div
                class="search p-8"
                ngClass.xs="p-4"
                fxLayout="column"
                fxLayoutAlign="start center"
                (click)="search()"
              >
                <mat-icon class="white-icon" ngClass.xs="s-20">search</mat-icon>
              </div>
            </div>

            <div
              class="advance-search"
              fxLayoutAlign.xs="start center"
              (click)="openAdvanceSearch = !openAdvanceSearch"
            >
              <img src="assets/icons/logos/Search-Filter.svg" />
            </div>
          </div>
          <div>
            <button
              mat-button
              class="accent-500"
              [matMenuTriggerFor]="exportMenu"
            >
              Export
            </button>
            <mat-menu #exportMenu="matMenu">
              <button mat-menu-item (click)="download('excel')">
                <span>Excel</span>
              </button>
              <button mat-menu-item (click)="download('csv')">
                <span>CSV</span>
              </button>
            </mat-menu>
          </div>
        </div>

        <div *ngIf="openAdvanceSearch" class="mb-2">
          <app-global-advance-search
            [columns]="advanceSearchColumns"
            (handleSearch)="AdvanceSearch($event)"
            [options]="options"
            [selectField]="selectField"
            [dateField]="dateField"
            [disableField]="disabled"
          ></app-global-advance-search>
        </div>
      </div>
    </div>
    <div
      class="table-container listing-height"
      fxLayout="column"
      fxLayoutAlign="space-between"
    >
      <div>
        <table
          mat-table
          matSort
          (matSortChange)="sortData($event)"
          [dataSource]="dataSource"
          class="w-100-p"
        >
          <ng-container matColumnDef="createdOn">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              <div class="text-uppercase infoTitle">DATE/TIME</div>
            </th>
            <td mat-cell *matCellDef="let element">
              <div>{{ plainConvertDateToLocal(element.createdOn) }}</div>
            </td>
          </ng-container>

          <ng-container matColumnDef="module">
            <th mat-header-cell *matHeaderCellDef>
              <div class="text-uppercase infoTitle">MODULE</div>
            </th>

            <td mat-cell *matCellDef="let element">
              <div>{{ element.module }}</div>
            </td>
          </ng-container>

          <ng-container matColumnDef="subModule">
            <th mat-header-cell *matHeaderCellDef>
              <div class="text-uppercase infoTitle">SUB-MODULE</div>
            </th>

            <td mat-cell *matCellDef="let element">
              <div>{{ element.subModule || "-" }}</div>
            </td>
          </ng-container>

          <ng-container matColumnDef="action">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              <div class="text-uppercase infoTitle">ACTION</div>
            </th>

            <td mat-cell *matCellDef="let element">
              <div>
                {{ element.action || "-" }}
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="description">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              <div class="text-uppercase infoTitle">DESCRIPTION</div>
            </th>

            <td mat-cell *matCellDef="let element">
              <div>
                {{ element.description }}
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="createdBy">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              <div class="text-uppercase infoTitle">USERNAME</div>
            </th>

            <td mat-cell *matCellDef="let element">
              {{ element?.username || element?.createdBy }}
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef width="5"></th>
            <ng-container>
              <td mat-cell *matCellDef="let element">
                <div fxLayout="column" fxLayoutAlign="end end">
                  <button mat-button [matMenuTriggerFor]="recordMenu">
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #recordMenu="matMenu">
                    <button mat-menu-item (click)="viewLogs(element)">
                      <span>View</span>
                    </button>
                  </mat-menu>
                </div>
              </td>
            </ng-container>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns; sticky: true"
            class="grayBackground"
          ></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayedColumns"
            class="table-hover"
            (dblclick)="viewLogs(row)"
          ></tr>
          <br />
        </table>
      </div>

      <div
        *ngIf="!dataSource.filteredData.length"
        class="text-center py-8 font-size-16 no-results"
      >
        No record found.
      </div>
      <div class="fixed-bottom">
        <mat-paginator
          fxLayout="row"
          fxLayoutAlign="center center"
          #paginator
          [length]="dataLength"
          [pageIndex]="filterOption.pageNumber"
          [pageSize]="filterOption.pageSize"
          [pageSizeOptions]="[5, 10, 25, 100]"
          (page)="pageEvent = $event; handlePage($event)"
        >
        </mat-paginator>
      </div>
    </div>
  </div>
</div>
