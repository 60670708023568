import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-skeleton-loader",
  template: ` <div [ngStyle]="getMyStyle()" class="skel-load loader"></div> `,
  styleUrls: ["./skeleton-loader.component.scss"],
})
export class SkeletonLoaderComponent implements OnInit {
  @Input() Cwidth;
  @Input() Cheight;
  @Input() circle: boolean;

  constructor() {
  
  }

  ngOnInit(): void {}

  getMyStyle() {
    const myStyle = {
      "width.rem": this.Cwidth ? this.Cwidth : "",
      "height.rem": this.Cheight ? this.Cheight : "",
      "border-radius.rem": this.circle ? this.circle : "",
    };
    return myStyle;
  }
}
