<h1 mat-dialog-title class="text-uppercase">Create e-Credit Line</h1>
<div mat-dialog-content>
  <div fxLayout="row">
    <form fxLayout="column" fxFlex [formGroup]="walletForm">
      <div
        fxLayout="row"
        class="font-size-16 p-8"
        fxFlex
        fxLayoutGap="10px"
        fxLayoutAlign="space-between start"
      >
        <div fxFlex="50">
          <span class="text-bold">Merchant ID: </span>
          <span>{{ data?.merchantId }}</span>
        </div>
        <div fxFlex="50">
          <span class="text-bold">Company Code: </span>
          <span>{{ data?.companyCode }}</span>
        </div>
      </div>
      <div fxLayout="column" fxFlex class="pt-4">
        <div fxLayout="row" fxLayoutGap="10px">
          <div fxLayout="column" fxFlex>
            <mat-form-field floatLabel="always" class="px-8">
              <mat-label>Country*</mat-label>
              <input
                matInput
                maxlength="100"
                minlength="1"
                formControlName="country"
                disabled="true"
              />
            </mat-form-field>
            <mat-form-field floatLabel="always" class="px-8">
              <mat-label>Currency*</mat-label>
              <input
                matInput
                maxlength="100"
                minlength="1"
                formControlName="currency"
                disabled="true"
              />
            </mat-form-field>

            <mat-form-field floatLabel="always" fxFlex class="px-8">
              <mat-label>Credit Limit</mat-label>
              <input
                matInput
                input="text"
                currencyMask
                maxlength="9"
                min="1"
                autocapitalize="off"
                autocomplete="off"
                autocorrect="off"
                spellcheck="off"
                required
                formControlName="creditLimit"
              />
            </mat-form-field>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<div mat-dialog-actions>
  <div fxLayout="row" fxLayoutAlign="end end" fxFlex fxLayoutGap="10px">
    <button mat-button (click)="cancel()" class="fuse-navy-900">Cancel</button>
    <button mat-button (click)="addCredit()" class="accent-500">Add</button>
  </div>
</div>
